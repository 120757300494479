import { BasicLayout, BlankLayout, PageView } from '@/layouts';

const companyType = process.env.VUE_APP_COMPANY_TYPE;
const mapping = {
  CODE_ONLY: '/projects',
  PUSH_ONLY: '/message',
  FULL_ACCESS: '/message'
};

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    redirect: companyType === 'PUSH_ONLY' ? mapping.PUSH_ONLY : mapping.CODE_ONLY,
    // meta: {
    //   title: 'Home'
    // },
    children: [
      {
        path: '/message',
        name: 'message',
        redirect: '/message/search',
        meta: {
          title: 'Message',
          icon: 'notification',
          permission: ['company/push'],
        },
        component: PageView,
        hideChildrenInMenu: true,
        children: [
          {
            path: '/message/search',
            name: 'message-list',
            meta: {
              permission: ['company/push']
            },
            component: () => import('@/views/message/Index.vue')
          },
          {
            path: '/message/compose',
            name: 'compose-message',
            meta: {
              title: 'Compose message',
              permission: ['company/push']
            },
            component: () => import('@/views/message/Compose.vue')
          },
          {
            path: '/message/:id',
            name: 'message-detail',
            meta: {
              title: 'Message detail',
              permission: ['company/push']
            },
            component: () => import('@/views/message/Compose.vue')
          }
        ]
      },
      {
        path: '/projects',
        name: 'project',
        redirect: '/projects/search',
        hideChildrenInMenu: true,
        meta: {
          title: 'Projects',
          icon: 'project',
          permission: ['company/code']
        },
        component: PageView,
        children: [
          {
            path: '/projects/search',
            name: 'project-list',
            meta: {
              permission: ['company/code']
            },
            component: () => import('@/views/project/Index.vue')
          },
          {
            path: '/projects/compose',
            name: 'project-compose',
            meta: {
              title: 'Project compose',
              permission: ['company/code']
            },
            component: () => import('@/views/project/compose.vue')
          },
          {
            path: '/projects/:projectId',
            name: 'project-detail',
            meta: {
              title: 'Edit',
              permission: ['company/code']
            },
            component: () => import('@/views/project/_Id/Index.vue')
          },
          {
            path: '/projects/:projectId/pages',
            name: 'page',
            redirect: '/projects/:projectId/pages/search',
            hideChildrenInMenu: true,
            meta: {
              title: 'Pages',
              permission: ['company/code']
            },
            component: BlankLayout,
            children: [
              {
                path: '/projects/:projectId/pages/search',
                name: 'page-list',
                meta: {
                  permission: ['company/code']
                },
                component: () => import('@/views/project/page/Index.vue')
              },
              {
                path: '/projects/:projectId/pages/compose',
                name: 'page-compose',
                meta: {
                  title: 'Page compose',
                  permission: ['company/code']
                },
                component: () => import('@/views/project/page/compose.vue')
              },
              {
                path: '/projects/:projectId/pages/:pageId',
                name: 'page-detail',
                meta: {
                  title: 'Edit',
                  permission: ['company/code']
                },
                component: () => import('@/views/project/page/_Id.vue')
              },
              {
                path: '/projects/:projectId/pages/:pageId/codes',
                name: 'code',
                redirect: '/projects/:projectId/pages/:pageId/codes/search',
                hideChildrenInMenu: true,
                meta: {
                  title: 'Codes',
                  permission: ['company/code']
                },
                component: BlankLayout,
                children: [
                  {
                    path: '/projects/:projectId/pages/:pageId/codes/search',
                    name: 'code-list',
                    meta: {
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project/page/codes/Index.vue')
                  },
                  {
                    path: '/projects/:projectId/pages/:pageId/codes/navi-code',
                    name: 'compose-navi-code',
                    meta: {
                      title: 'Compose code',
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project/page/create-code/OthersCode.vue')
                  },
                  {
                    path: '/projects/:projectId/pages/:pageId/codes/plain-voice-code',
                    name: 'compose-plain-voice-code',
                    meta: {
                      title: 'Compose code',
                      permission: ['company/code']
                    },
                    component: () =>
                      import('@/views/project/page/create-code/voice-code/PlainVoiceCode.vue')
                  },
                  {
                    path: '/projects/:projectId/pages/:pageId/codes/rich-voice-code',
                    name: 'compose-rich-voice-code',
                    meta: {
                      title: 'Compose code',
                      permission: ['company/code']
                    },
                    component: () =>
                      import('@/views/project/page/create-code/voice-code/RichVoiceCode.vue')
                  },
                  {
                    path: '/projects/:projectId/pages/:pageId/codes/navi-code/:codeId',
                    name: 'navi-code-detail',
                    meta: {
                      title: 'Edit',
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project/page/edit-code/Index.vue')
                  },
                  {
                    path: '/projects/:projectId/pages/:pageId/codes/voice-code/:codeId',
                    name: 'voice-code-detail',
                    meta: {
                      title: 'Edit',
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project/page/create-code/voice-code/_Id.vue')
                  },
                  {
                    path: '/projects/:projectId/pages/:pageId/category',
                    name: 'category',
                    meta: {
                      title: 'Category management',
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project/page/category/index.vue')
                  }
                ]
              }
            ]
          },
          {
            path: '/projects/:projectId/archived-pages',
            name: 'project-archived-page',
            redirect: '/projects/:projectId/archived-pages/search',
            hideChildrenInMenu: true,
            meta: {
              title: 'Archived pages',
              permission: ['company/code']
            },
            component: BlankLayout,
            children: [
              {
                path: '/projects/:projectId/archived-pages/search',
                name: 'project-archived-page-list',
                meta: {
                  permission: ['company/code']
                },
                component: () => import('@/views/project/page-archive/Index.vue')
              },

              {
                path: '/projects/:projectId/archived-pages/:pageId/codes',
                redirect: '/projects/:projectId/archived-pages/:pageId/codes/search',
                hideChildrenInMenu: true,
                meta: {
                  permission: ['company/code']
                },
                component: BlankLayout,
                children: [
                  {
                    path: '/projects/:projectId/archived-pages/:pageId/codes/search',
                    name: 'project-archived-code-list',
                    meta: {
                      title: 'Archived codes',
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project/page-archive/codes/Index.vue')
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/archived-projects',
        name: 'archived-projects',
        redirect: '/archived-projects/search',
        hideChildrenInMenu: true,
        meta: {
          title: 'Archived projects',
          icon: 'inbox',
          permission: ['company/code']
        },
        component: PageView,
        children: [
          {
            path: '/archived-projects/search',
            name: 'archived-project-list',
            meta: {
              permission: ['company/code']
            },
            component: () => import('@/views/project-archive/Index.vue')
          },
          {
            path: '/archived-projects/:projectId/pages',
            name: 'archived-project-pages',
            redirect: '/archived-projects/:projectId/pages/search',
            hideChildrenInMenu: true,
            meta: {
              title: 'Archived pages',
              permission: ['company/code']
            },
            component: BlankLayout,
            children: [
              {
                path: '/archived-projects/:projectId/pages/search',
                name: 'archived-project-page-list',
                meta: {
                  permission: ['company/code']
                },
                component: () => import('@/views/project-archive/page/Index.vue')
              },
              {
                path: '/archived-projects/:projectId/pages/:pageId/codes',
                redirect: '/archived-projects/search/:projectId/pages/:pageId/codes/search',
                hideChildrenInMenu: true,
                meta: {
                  permission: ['company/code']
                },
                component: BlankLayout,
                children: [
                  {
                    path: '/archived-projects/search/:projectId/pages/:pageId/codes/search',
                    name: 'archived-project-codes',
                    meta: {
                      title: 'Archived codes',
                      permission: ['company/code']
                    },
                    component: () => import('@/views/project-archive/page/codes/Index.vue')
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        redirect: '/settings/email',
        hidden: false,
        meta: {
          title: 'Settings',
          icon: 'setting',
          permission: ['company/push', 'company/code']
        },
        hideChildrenInMenu: false,
        component: () => import('@/views/setting/Index.vue'),
        children: [
          {
            path: '/settings/email',
            name: 'setting-email',
            component: () => import('@/views/setting/Email.vue'),
            meta: { title: 'Profile Setting', permission: ['company/push', 'company/code'] }
          },
          {
            path: '/settings/change-password',
            name: 'setting-change-password',
            component: () => import('@/views/setting/ChangePassword.vue'),
            meta: { title: 'Change Password', permission: ['company/push', 'company/code'] }
          },
          {
            path: '/settings/header-footer-setting',
            name: 'header-footer-setting',
            component: () => import('@/views/setting/HeaderAndFooter.vue'),
            meta: { title: 'Header & Footer Setting', permission: ['company/code'] }
          },
          {
            path: '/settings/speech-setting',
            name: 'speech-setting',
            component: () => import('@/views/setting/SpeechSetting.vue'),
            meta: { title: 'Dictionary Setting', permission: ['company/code'] }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
];

export const constantRouterMap = [
  {
    path: '/user',
    name: 'user',
    redirect: '/user/login',
    component: () => import('@/views/user/Index.vue'),
    children: [
      {
        path: '/user/request',
        name: 'request',
        component: () => import('@/views/user/Request.vue')
      },
      {
        path: '/user/register',
        name: 'register',
        component: () => import('@/views/user/Register.vue')
      },

      {
        path: '/user/login',
        name: 'login',
        component: () => import('@/views/user/Login.vue')
      },
      {
        path: '/user/forgot',
        name: 'forgot',
        component: () => import('@/views/user/ForgotPassword.vue')
      }
    ]
  },
  {
    path: '/oauth',
    name: 'oauth',
    meta: {},
    hidden: true,
    component: () => import('@/views/oauth/Index.vue'),
    children: [
      {
        path: '/oauth/login',
        name: 'oauth-login',
        meta: {},
        hidden: true,
        component: () => import('@/views/oauth/Login.vue')
      }
    ]
  },
  {
    path: '/block',
    name: 'Block',
    component: () => import('@/views/Block.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404.vue')
  }
];
