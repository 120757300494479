import Vue from 'vue';
import { univoiceApi } from '@/api';

const initialState = () => ({
  pollyEngine: 'standard'
});

const store = {
  namespaced: true,
  state: {
    ...initialState()
  },
  getters: {
    pollyEngine: (state) => state.pollyEngine
  },
  actions: {
    async fetchRemoteConfig({ commit }) {
      try {
        const res = await univoiceApi.remoteConfig.getRemoteConfig('pollyEngine')
        commit('SET', { pollyEngine: res.str_val })
      } catch (error) {
        console.log('Error: ', error)
      }
    },
  },
  mutations: {
    SET: (state, data) => {
      Vue.set(state, 'pollyEngine', data.pollyEngine);
    }
  }
}

export default store
