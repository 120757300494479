import Vue from 'vue';
import { univoiceApi } from '@/api';

const initialState = () => ({
  entities: {},
  ids: [],
  projectsAndPages: []
});

const store = {
  namespaced: true,
  state: {
    ...initialState()
  },
  getters: {
    ids: state => state.ids,
    projects: (state, getters) => {
      return getters.ids.map(id => state.entities[id]) || [];
    },
    projectById: state => id => state.entities[id],
    projectsAndPages: state => state.projectsAndPages
  },
  actions: {
    fetchProjects({ dispatch }, companyId) {
      univoiceApi.project
        .find(companyId, {
          filter: { where: { status: { neq: 'ARCHIVE' } }, limit: 200, order: 'created DESC' }
        })
        .then(projects => {
          dispatch('loadProjects', { payload: projects });
        });
    },
    getProjectsAndPagesByCompany({ getters, commit }, companyId) {
      if (getters.projectsAndPages && getters.projectsAndPages.length > 0) {
        return getters.projectsAndPages;
      } else {
        univoiceApi.project
          .find(companyId, {
            filter: {
              where: { status: { neq: 'ARCHIVE' } },
              include: ['projects'],
              limit: 200,
              order: 'created DESC'
            }
          })
          .then(projects => {
            for (let i = 0; i < projects.length; i++) {
              const project = projects[i];
              // Remove archive pages inside project
              project.projects = project.projects.filter(page => page.status !== 'ARCHIVE');
            }
            commit('SET_PROJECTS_AND_PAGES', { payload: projects });
          });
      }
    },
    getProjectById({ commit, getters }, { companyId, projectId }) {
      if (getters.projectById(projectId)) {
        return getters.projectById(projectId);
      } else {
        return new Promise(resolve => {
          univoiceApi.project.getProjectById(companyId, projectId).then(project => {
            commit('SET', { payload: project });
            resolve(project);
          });
        });
      }
    },
    addProject({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET', payload);
        resolve(1);
      });
    },
    updateProjectById({ commit }, payload) {
      return new Promise(resolve => {
        commit('UPDATE', payload);
        resolve(1);
      });
    },
    removeProject({ commit }, payload) {
      return new Promise(resolve => {
        commit('REMOVE', payload);
        resolve(1);
      });
    },
    loadProjects({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET', payload);
        resolve(1);
      });
    },
    reset({ commit }) {
      return new Promise(resolve => {
        commit('RESET');
        resolve(1);
      });
    }
  },
  mutations: {
    SET: (state, { payload }) => {
      if (Array.isArray(payload)) {
        let newIds = [...state.ids];
        const newEntities = Object.assign({}, state.entities);

        payload.forEach(values => {
          const id = values.id;
          newIds = [...newIds, id];
          newEntities[id] = Object.assign({}, newEntities[id], values);
        });

        const ids = Array.from(new Set(newIds));
        const entities = Object.assign({}, newEntities);
        Vue.set(state, 'ids', ids);
        Vue.set(state, 'entities', Object.assign({}, entities));
      } else {
        const id = payload.id;
        const newIds = Array.from(new Set([id, ...state.ids]));
        const newEntities = Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], payload)
        });
        Vue.set(state, 'ids', newIds);
        Vue.set(state, 'entities', newEntities);
      }
    },
    REMOVE: (state, payload) => {
      const newEntities = Object.assign({}, state.entities);
      let newIds = [...state.ids];

      if (payload.id in newEntities) {
        delete newEntities[payload.id];
      }
      newIds = newIds.filter(id => id in newEntities);
      Vue.set(state, 'ids', newIds);
      Vue.set(state, 'entities', newEntities);
    },
    UPDATE: (state, payload) => {
      // const updatedMessageBox = payload
      const newEntities = Object.assign({}, state.entities);
      let newIds = [...state.ids];

      if (payload.id in newEntities) {
        newEntities[payload.id] = Object.assign({}, newEntities[payload.id], payload);
      }
      newIds = newIds.filter(id => id in newEntities);
      Vue.set(state, 'ids', newIds);
      Vue.set(state, 'entities', newEntities);
    },
    RESET: state => {
      Object.assign(state, initialState());
    },
    SET_PROJECTS_AND_PAGES: (state, { payload }) => {
      Vue.set(state, 'projectsAndPages', payload);
    },
    RESET_PROJECTS_AND_PAGES: state => {
      Vue.set(state, 'projectsAndPages', []);

    },
  }
};

export default store;
