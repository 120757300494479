//
//
//
//
//
//
//
//

import { AppDeviceEnquire } from '@/utils/mixin';
import { mixin as langMixin } from '@/mixins/i18n-mixin';
import ja from 'ant-design-vue/lib/locale-provider/ja_JP';
import en from 'ant-design-vue/lib/locale-provider/en_US';
import moment from 'moment';
import 'moment/locale/ja';
import 'moment/locale/en-au';

// TODO, try to add something for deploy
export default {
  name: 'app',
  mixins: [AppDeviceEnquire, langMixin],
  data() {
    return {};
  },
  created() {
    console.log('test deploy')
    const lang = this.$ls.get('lang', 'ja');
    this.setLang(lang);
    const globalStyle = this.$ls.get('globalStyle', {
      fontFamily: `'Noto Sans JP', Helvetica, arial, sans-serif`
    });
    this.setGlobalStyle(globalStyle);
  },
  computed: {
    locale() {
      if (this.$i18n.locale === 'ja') {
        return ja;
      }
      return en;
    }
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        moment.locale(value);
      },
      immediate: true
    }
  }
};
