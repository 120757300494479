import serverError from './server-error-ja';

export default {
  ...serverError,
  format: {
    scheduling: '*開始時間は{minSending}より後でなければなりません',
    repeat: '繰り返しなし | 1 回 | {count} 回',
    Repeatedly: '繰り返し: なし | 繰り返し: {count}回 | 繰り返し: {count}回',
    autoPushAfter: '*自動送信はコード作成時から{minSending}分後以降でなければなりません'
  },
  menu: {
    Company: 'アカウント',
    Projects: 'プロジェクト',
    Message: 'メッセージ',
    Settings: '設定'
  },
  message: {
    grid: {
      ID: 'ID',
      Content: 'タイトルと内容',
      Sender: '配信元アカウント',
      Status: 'ステータス',
      Scheduling: '配信スケジュール',
      Target: '配信先設定',
      Created: '作成日時',
      Updated: '更新日時',
      Actions: '操作',
      Languages: '配信言語',
      Attachments: '添付ファイル',
      MessageID: 'メッセージID',
      Title: 'タイトル',
      Sending: '配信日',
      Like: 'いいね'
    },
    compose: {
      'End time': '終了日',
      'Please pick a date': '日付を選択してください',
      end_time: '終了日',
      Daily: '毎日',
      Weekly: '毎週',
      Monthly: '毎月',
      Advance: '詳細設定',
      'Daily message single': '毎日, {time}',
      'Daily message multi': '{repeat}日毎, {time}',
      'Weekly message single': '毎週 {days}曜日, {time}',
      'Weekly message multi': '{repeat}週間毎, {days}, {time}',
      'Monthly message single': '毎月 {days}日, {time}',
      'Monthly message multi': '{months}の{days}日, {time}',
      Last: '月末',
      Weeks: '週',
      Days: '日',
      Months: '月',
      On: '指定日',
      Custom: 'カスタム',
      PDFIsRequired: 'PDFが必要です',
      PDFIsInvalid: 'PDFが無効です',
      ImageIsRequired: '画像が必要です',
      ImageIsInvalid: '画像が無効です',
      URLIsRequired: 'URLが必要です',
      URLIsInvalid: 'URLが無効です',
      'Repeat every': '繰り返す間隔'
    }
  },
  project: {
    grid: {
      Name: 'プロジェクト名',
      Active: 'ステータス',
      Created: '作成日',
      Updated: '変更日',
      PublishSetting: '公開設定',
      Actions: '操作',
      EditWebsite: 'サイトエディタ',
      Photo: '写真',
      Description: '説明',
      Template: 'テンプレート',
      Path: 'パス',
      URL: 'URL',
      Dictionary: '辞書設定'
    }
  },
  page: {
    grid: {
      Name: 'ページ名'
    }
  },
  code: {
    grid: {
      ID: 'ID',
      Photo: '写真',
      Title: 'コード名',
      Type: 'コード種別',
      Status: 'ステータス',
      Created: '作成日',
      Updated: '変更日',
      CodeID: 'コードID',
      CreatedDate: '作成日',
      EditWebsite: 'サイトエディタ',
      PublishSetting: '公開設定',
      Category: 'カテゴリー',
      Actions: '操作',
      Dictionary: '辞書設定'
    },
    codeSize: {
      XS: 'XS サイズ（3×3 ブロックシンボル）：漢字 60 文字程度',
      S: 'S サイズ （6×6 ブロックシンボル）：漢字 280 文字程度',
      M: 'M サイズ（9×9 ブロックシンボル）：漢字 640 文字程度',
      L: 'L サイズ （10×10 ブロックシンボル）：漢字 800 文字程度',
      XL: 'XL サイズ （12×12 ブロックシンボル）：漢字 1150文字程度'
    },
    errorLevel: {
      L: 'L : エラー訂正強度低（10%冗長）',
      M: 'M : エラー訂正強度中（20%冗長）',
      H: 'H : エラー訂正強度高（40%冗長）'
    },
    importCode: {
      'Are you sure to generate code?':
        'コードを生成してもよろしいでしょうか？生成したコードを後から編集することはできません。中身を確認したい場合はいったん下書きとして保存してください。'
    }
  },
  billing: {
    grid: {
      Id: 'ID',
      Total: '全て',
      Status: 'ステータス',
      InvoiceDate: '発行日',
      Updated: '変更日'
    }
  },
  account: {
    detail: {
      codeCreateFeeRequired: 'コード作成料金を入力してください。',
      priceRequired: '金額を入力してください。',
      countRequired: '毎月の最大配信メッセージ数を入力してください。',
      voiceCode: '音声コード(円)',
      naviCode: 'NAVIコード(円)',
      spotCode: 'SPOTコード(円)',
      mixCode: '複合コード（NAVI＋SPOT）(円)'
    }
  },
  PortalSite: {
    Grid: {
      Name: 'サイト名',
      CreatedDate: '作成日時',
      PublishSetting: '公開設定',
      EditWebsite: 'サイトエディタ',
      Actions: '操作',
      MobileActive: 'スマホアプリに表示',
      Photo: '写真',
      Description: '説明'
    }
  },
  'Log out': 'ログアウト',
  Cancel: 'キャンセル',
  'Uni-Voice for UD': 'Uni-Voice for UD',
  Title: 'タイトル',
  'Code name': 'コード名',
  'Title is required': 'タイトルが必要です',
  'Title max 65 length': 'タイトルを65字以内で入力してください',
  'Title And Content': 'タイトルと内容',
  Content: '内容',
  'Content is required': '本文が必要です',
  TitleMaxLength: '本文を{length}字以内で入力してください',
  ContentMaxLength: '本文を{length}字以内で入力してください',
  siteName: 'サイト名',
  Total: '全',
  TotalMoney: '金額',
  'No data': 'データがありません。',
  'Rejected reason': '拒否理由',
  'Message content': 'メッセージの内容',
  Next: '次へ',
  Targeting: '配信先設定',
  'A valid targeting condition is required': '配信対象ユーザー設定が無効です',
  Scheduling: '配信スケジュール',
  Submit: '確認',
  'Compose Submit': '配信する',
  Error: 'エラー',
  Publish: '公開する',
  'Push has been saved.': '通知メッセージを配信できました。',
  Status: 'ステータス',
  Search: '検索',
  Reset: 'リセット',
  'New Compose': '新規通知メッセージ',
  Target: '配信先設定',
  Created: '作成日',
  Updated: '更新日時',
  Actions: '操作',
  'Message statistic': '通知メッセージの統計',
  'Gender distribution': '性別',
  'No data available.': 'データがありません。',
  'City distribution': '市町村',
  City: '市町村',
  Count: '数',
  'Age distribution': '年令',
  Age: '年令',
  'Progression by time': '経過時間による推移',
  Attachment: '添付',
  'URL is not valid': '無効なURL',
  'Add languages': '言語を追加',
  Select: '選択',
  Switch: '切り替える',
  Loading: 'しばらくお待ちください。',
  'Loading...': 'しばらくお待ちください。',
  Gender: '性別',
  Location: 'ロケーション',
  'All users': '全てのユーザー',
  'Starting time': '開始時間',
  'Select Time': '日付を選択してください',
  'Repeatedly Times': '繰り返し: なし | 繰り返し: {count}回 | 繰り返し: {count}回',
  'Duration (hours):': '期間（単位：時間）:',
  'Please select duration': '期間を選択してください',
  Upload: 'アップロード',
  'Image must smaller than 10MB!': '10MB以下の画像をアップロードしてください。',
  Male: '男性',
  Female: '女性',
  Others: 'その他',
  Start: '開始',
  Repeat: '繰り返し',
  Duration: '期間',
  'View statistics': '統計を見る',
  Edit: '編集',
  'Company Push Only': '通知システム',
  'Company Full Access': 'Uni-Voice for UD',
  'Content management system': 'コンテンツ管理システム',
  'Account information': 'アカウントの情報',
  Email: 'メール',
  Profile: 'プロフィール',
  'Dictionary Setting': '辞書設定',
  'Profile Setting': 'プロフィール設定',
  'Header & Footer Setting': 'ヘッダー＆フッター設定',
  'Email Settings': 'プロフィール',
  'setting-email': 'プロフィール',
  'Contact phone Settings': '連絡先電話番号',
  'Company name Settings': 'アカウント名',
  'Company name placeholder': '会社名、団体名など',
  'Company address Settings': '住所',
  'Company URL Settings': 'Webサイト',
  'Descriptions Settings': '説明',
  'Please input your E-mail!': 'メールアドレスを入力してください。',
  'The input is not valid E-mail!': '入力されたメールアドレスは無効です。',
  'Enter your email': 'メールを入力',
  'Contact email': '連絡先のメールアドレス',
  'Contact phone': '電話番号',
  'Please enter a phone number': '電話番号を入力してください。',
  'Company name': 'アカウント名',
  'Please enter company name': 'アカウント名を入力してください。',
  'Company address': '住所',
  'Please enter company address': '住所を入力してください',
  'Your registration has been received.': '登録申請を受け付けました。',
  'We will approve and send your account password to email ':
    '確認後、連絡先メールアドレスにパスワードをお知らせします。',
  'Go to Sign in': 'ログイン画面へ',
  'The token is expired or does not exist': '無効なトークンまたは存在しないトークンです。',
  Registration: '新規登録',
  'Already have account?': 'アカウントをお持ちの方',
  'Sign in': 'ログイン',
  Register: '登録する',
  'Please check your email.': 'メールを確認してください。',
  'We have sent registation form to your entered email':
    '入力いただいたメールアドレスに登録情報を送信しました。',
  'Email is already exists. Please use other email.':
    '既に登録済みのメールアドレスです。別のものを入力してください。',
  'Reset your password': 'パスワードをリセット',
  'Please input verification code': '確認コードを入力してください。',
  Resend: '再送信',
  'At least 8-digit password, case sensitive.':
    '少なくとも8桁のパスワードが必要です（大文字と小文字を区別）',
  'New Password': '新しいパスワード',
  'Send code': '確認コードを送信',
  'Sign In': 'ログイン',
  'Change password has been successful': 'パスワードが変更されました。',
  'Reset code is being sent...': '確認コードを送信中です',
  Notice: 'ご注意',
  'Please update your password': 'パスワードを更新してください',
  'Please enter your password': 'パスワードを入力してください',
  'Enter your password': 'パスワードを入力',
  'Please enter confirm password': 'もう一度パスワードを入力してください',
  'Confirm password': 'パスワードの確認',
  'Two passwords are inconsistent': 'パスワードが一致しません',
  'Don’t have account?': 'アカウントを持ってない方',
  'Forget your password?': 'パスワードを忘れた方',
  'Reset password?': 'パスワードをリセットする',
  'Login failed': 'ログインできませんでした。',
  'Change password': 'パスワードを変更する',
  'setting-change-password': 'パスワードを変更する',
  Password: 'パスワード',
  'Menu Change Password': 'パスワード',
  Image: '画像',
  'PDF Attachment': 'PDF',
  'Target Users': '配信対象ユーザー',
  'Add Filter': 'フィルターを追加',
  All: '全て',
  Waiting: '処理中',
  Pending: '処理中',
  Done: '完了',
  'Save as draft': '下書きとして保存する',
  Rejected: '拒否済',
  Approved: '承認済',
  Sending: '配信中',
  'Gender of user': 'ユーザーの性別',
  'Age of user': 'ユーザーの年令',
  'Location to which the user belongs': 'ユーザーのロケーション',
  'Repeated Times': '繰り返し',
  hours: '時間',
  female: '女性',
  male: '男性',
  other: 'その他',
  ALL: '全て',
  WAITING: '処理中',
  PENDING: '処理中',
  DONE: '完了',
  REJECTED: '拒否済',
  APPROVED: '承認済',
  SENDING: '配信中',
  CANCEL: 'キャンセル',
  PUBLISHED: '生成済み',
  'PUBLISHED(edited)': '生成済み(編集済み)',
  'PUBLISHED(updating)': '生成済み(処理中)',
  ABOLISHED: '無効',
  FAILED: '無効',
  Home: 'ホーム',
  Company: 'アカウント',
  Projects: 'プロジェクト',
  Message: '通知メッセージ',
  'Import File Message': 'メッセージ',
  'Message list': '通知メッセージ一覧',
  'Compose message': '新規通知メッセージ',
  'Message detail': '通知メッセージの詳細',
  'Message Statistic': '通知メッセージの統計',
  Settings: '設定',
  'Email Setting': 'プロフィール設定',
  'Change Password': 'パスワードを変更する',
  'Network error': 'ネットワークに接続できませんでした',
  'Authorization verification failed': '確認できませんでした。',
  'Server error, please try again later.': 'サーバーエラーです。やり直してください。',
  and: '追加',
  'Please select': '選択してください。',
  Option: '選択',
  items: 'メッセージ',
  'Code data': 'コードデータ',
  Manual: 'マニュアル',
  'Import File': 'ファイルをインポート',
  'Code size': 'コードサイズ',
  'EC error level': 'エラー訂正レベル',
  'Add Code from Mobile': 'モバイル端末からコードを追加',
  'Add Code': 'コードを追加',
  Save: '保存する',
  'Save project': '作成する',
  'Change Project': '保存する',
  'Please input your project name': 'プロジェクト名を入力してください',
  DuplicateSuccess: 'コードが複製されました。',
  DeletedSuccess: 'コードが削除されました。',
  Generate: '生成する',
  'Re-Generate': '再生成する',
  Abolished: '無効',
  Published: '生成済み',
  Failed: '無効',
  'Published(edited)': '生成済み(編集済み)',
  'Published(updating)': '生成済み(処理中)',
  'Created date': '作成日',
  'View code list': 'コード一覧を見る',
  'Created Project': 'プロジェクトが作成されました。',
  'Add Point': 'ポイントを追加',
  Remove: '削除する',
  'Your location': '現在位置',
  Type: 'コード種別',
  'Import Type': 'コード種別を選択',
  'Import Code Label': 'モバイル端末からコードを追加',
  'Navi Type': 'NAVI',
  'Faci Type': 'SPOT',
  'Code Name': 'コード名',
  'Code Content': 'コード内容',
  'Enter code name in english': '英語でコード名を入力',
  ENG: '英語',
  'Enter code name in japanese': '日本語でコード名を入力',
  JPN: '日本語',
  'Enter code name in chinese': '中国語でコード名を入力',
  CHI: '中国語',
  'Enter code name in korean': '韓国語でコード名を入力',
  KOR: '韓国語',
  Preview: 'プレビュー',
  'Compose project': 'プロジェクトを作成する',
  'project-compose': 'プロジェクトを作成する',
  'Project name max length 50': 'プロジェクト名は50文字以内で入力してください',
  voice_code: '音声コード',
  spot_code: 'SPOTコード',
  navi_code: 'NAVIコード',
  mix_code: '複合コード',
  'Project compose': 'プロジェクト作成',
  Compose: 'プロジェクト作成',
  'Code compose': 'コードの作成',
  'Compose code': 'コードを作成',
  'Compose voice code': 'コード作成',
  'Title code demo text': 'コード名を入力',
  'Point Name': 'ポイント名',
  'Point Location': '位置情報',
  'Point Categories': 'カテゴリー',
  'Point Please select categories': 'SPOTのカテゴリーを選択してください',
  'Point Description': '説明',
  'Point Action': '操作',
  'Save Point': '保存する',
  'Please input point name in english': '英語のポイント名が必要です。',
  'Please input point name in japanese': '日本語のポイント名が必要です。',
  'Please input point name in chinese': '中国語のポイント名が必要です。',
  'Please input point name in korean': '韓国語のポイント名が必要です。',
  'Please input your latitude': '緯度が必要です。',
  'Please input your longitude': '経度が必要です。',
  'Latitude is invalid': '緯度が無効です',
  'Longitude is invalid': '経度が無効です',
  NotFoundCodeById:
    'ID {count} のインスタンスは見つかりませんでした。|ID {count} のインスタンスは見つかりませんでした。| ID {count} のインスタンスは見つかりませんでした。',
  SaveDraftSuccess: '下書きとして保存されました。',
  SaveGenerateSuccess: 'コードが作成されました。',
  SaveAbolishedSuccess: 'コードを無効にしました。',
  Name: '名称',
  Active: '有効にする',
  'Edit Code': 'コードの編集',
  Project: 'プロジェクト',
  Duplicate: '複製する',
  'Code Preview': 'コード詳細',
  'Code list': 'コード一覧',
  'code-list': 'コード一覧',
  'Code List': 'コード一覧',
  Codes: 'コード一覧',
  'Are you sure to duplicate this code?': 'このコードを複製してよいですか?',
  ID: 'ID',
  'Code type': 'コード種別',
  'Code Type': 'コード種別',
  'Create date': '作成日',
  'Updated date': '変更日',
  Download: 'ダウンロード',
  'Switch Project': 'プロジェクトを切り替える',
  'Select code to import': 'コードを追加',
  Import: '確認',
  InvalidPhone: '半角数字10-11桁の形式で入力してください（ハイフンなしで入力してください。）',
  View: '詳細表示',
  ChangeView: '表示切替',
  ViewCell: '詳細表示',
  Delete: '削除する',
  Archive: 'アーカイブ',
  'A verification code for resetting has been sent. Please check your email.':
    'パスワードをリセットするためのコードを送信しました。メールを確認してください',
  'Click or drag file TYPES to this area to upload':
    'アップロードするにはここにファイル {types} をドラッグしてください。',
  'File must smaller than 10MB': '10MB以下のファイルをアップロードしてください。',
  'File must smaller than 50MB': '50MB以下のファイルをアップロードしてください。',
  'No any code need to generate': 'コードを生成する必要ありません。',
  Ready: '準備完了',
  Successful: '成功',
  Processing: '処理中',
  VALIDATION_FAILED: 'ファイルにエラーが発生しました。もう一度確認ください。',
  ENCODE_ERROR: 'データ量が多すぎです。やり直してください。',
  UpdateProjectSuccess: 'プロジェクトを更新しました。',
  'These codes are generated': 'コードを生成しました。',
  'Generate error': '生成したコードにエラーがありました。確認してください。',
  'Cannot generate code': 'コードを生成できませんでした。',
  'Company URL': 'Webサイト',
  Descriptions: '説明',
  'Enter old password': '現在のパスワード',
  'Enter new password': '新規パスワード',
  'Confirm new password': '新規パスワードを再入力',
  'Please input old password': '現在のパスワードを入力してください',
  'Please input new password': '新規パスワードを入力してください',
  'Please input confirm password': '新規パスワードをもう一度入力してください',
  'Confirm password does not match': 'パスワードが一致しません',
  'Required choose at least a category': '少なくともひとつのカテゴリを選択してください。',
  '6 at most': 'スポットを6個まで選択してください。',
  Navi: 'NAVIコード',
  Spot: 'SPOTコード',
  'Status Draft': '下書き',
  'De-active': '無効にする',
  Placeholder: '現在のパスワードを入力してください',
  'Place holder': '新規パスワードを入力してください',
  'Password length at least 8 characters': 'パスワードは8文字以上を入力してください',
  'Confirm password is required!': 'パスワードが一致しません',
  Update: '更新する',
  'Please input your old password!': '現在のパスワードを入力してください',
  'Code name is required.': 'コード名を入力してください',
  'Pick a location': 'ポイントを選択する',
  'Please input your Company Address!': '住所を入力してください',
  'Please input your Company Name!': 'アカウント名を入力してください',
  'Please input your Company URL!': 'Webサイトを入力してください',
  'Please input your Descriptions!': '説明を入力してください',
  'Project name': 'プロジェクト名',
  'Project list': 'プロジェクト一覧',
  Success: '成功',
  'Incorrect password': '現在のパスワードが正しくありません。やり直してください',
  'Edit project': 'プロジェクトの編集',
  'project-detail': 'プロジェクトの編集',
  'Invalid current password': '現在のパスワードが正しくありません。やり直してください',
  'Change password is succeed': 'パスワードを変更しました。',
  ChangeEmailSuccess: 'プロフィールを変更しました。',
  'Enter a location': 'マップを検索する。',
  'Are you sure to delete this code': 'このコードを削除してもよろしいでしょうか？',
  'Would you like to duplicate this code？':
    'このコードを複製してもよろしいでしょうか？複製して下書きとして保存します。',
  'Are you sure to generate code?': 'コードを生成してもよろしいでしょうか？',
  'Are you sure to re-generate code ?': 'コードを再生成してもよろしいですか？',
  Yes: 'はい',
  No: 'いいえ',
  'Reset code': '確認コード',
  'Edit code': 'コードを編集',
  'code-detail': 'コードを編集',
  'voice-code-detail': 'コードを編集',
  'Confirmation code is wrong. Please try again.': '無効な確認コードです。やり直してください。',
  DRAFT: '下書き',
  Draft: '下書き',
  days: '日間',
  month: 'ヶ月間',
  'There is a problem with the uploaded file. This file will not be uploaded':
    'アップロードされたファイルにエラーがありました。アップロードできませんでした。',
  'Please input URL format': '有効なURL形式を入力してください。(例:https://example.com)',
  LAT: '緯度',
  LONG: '経度',
  Lat: '緯度',
  Long: '経度',
  'Message title': 'メッセージタイトル',
  'Total amount': '合計',
  'Invoice date': '請求書日',
  'Issued date': '発行日',
  'Paid date': '払い日',
  'Unit price': '単価(円)',
  CodeQuantity: '作成コード数',
  PushQuantity: '配信メッセージ数',
  'Voice code': '音声コード',
  'Navi code': 'NAVIコード',
  'Mix code': '複合コード（NAVI+SPOT)',
  'Spot code': 'SPOTコード',
  'Sub total': '小計',
  'On Going': '処理中',
  ON_GOING: '処理中',
  Paid: '払い済み',
  PAID: '払い済み',
  Unpaid: '未払い',
  UNPAID: '未払い',
  ISSUED: '発行済み',
  Id: 'ID',
  'Choose a code type to compose': '作成するコード種別を選択してください。',
  'Voice code(single/multiples)': '音声コード：単言語・多言語',
  'Voice code with plain content(single/multiples)':
    'プレーンテキスト版の音声コード：単言語・多言語 ',
  'Voice code with rich content(single/multiples)':
    'ウェブコンテンツ(HTML)版の音声コード：単言語・多言語',
  'Navi code / Spot code / Mix code': 'NAVIコード・SPOTコード・複合コード',
  Ok: '確認',
  'Language is required': '言語を選択してください',
  'Add Language': '言語を追加する',
  Language: '言語',
  'This code is created': 'コードを下書きとして保存しました。',
  'File name': 'ファイル名',
  'There was an error in the generated code. please confirm.':
    '生成されたコードにエラーがありました。確認してください。',
  Billings: '請求書',
  'Billing list': '請求書一覧',
  'View Bill': '詳細表示',
  Back: '戻る',
  'This message is updated': '配信がキャンセルされました。',
  'Updated message is fail': 'メッセージを更新できませんでした。',
  'Searching zip code is success': '郵便番号が見つかりました。',
  'Searching zip code is fail': '郵便番号が見つかりませんでした。',
  'Person in charge': '担当者名',
  'Zip code': '郵便番号',
  Prefecture: '都道府県',
  'Roll Back': '下書きに戻す',
  'Please enter company prefecture': '都道府県を選択してください。',
  'Please enter person in charge': '担当者を入力してください。',
  'Please enter company city': '市を選択してください。',
  'Zip code is 7 length': '郵便番号は7桁です',
  'Are you sure to publish this message now ?': 'このメッセージを配信します。よろしいですか？',
  'Please enter company url': 'Webサイトを入力してください。',
  'Please enter descriptions': '説明を入力してください。',
  'Company url': 'Webサイト',
  'Are you sure to cancel publishing this message?':
    '通知メッセージの配信をキャンセルしてもよろしいでしょうか',
  'Zip code is required': '郵便番号を入力してください。',
  'There is no applicable address.': '該当する住所はありません。',
  'All Rights Reserved.': '全著作権所有.',
  'App Target': '配信先のアプリ',
  'User can push to user app': '配信対象のアプリの選択',
  End: '終了',
  'This message is deleted': 'このメッセージが削除されました。',
  'Are you sure delete this message': 'この通知メッセージを削除してもよろしいでしょうか？',
  Both: '両方',
  'Please set repeatly lower than 10': '10以下を設定してください。',
  AppTarget: '配信先のアプリ',
  'Advanced setting schedule': '詳細設定スケジュール',
  Monday: '月',
  Tuesday: '火',
  Wednesday: '水',
  Thursday: '木',
  Friday: '金',
  Saturday: '土',
  Sunday: '日',
  January: '1月',
  February: '2月',
  March: '3月',
  April: '4月',
  May: '5月',
  June: '6月',
  July: '7月',
  August: '8月',
  September: '9月',
  October: '10月',
  November: '11月',
  December: '12月',
  'Please set a repeat': '繰り返しを設定してください。',
  'Please set days': '日を設定してください。',
  'Please set month': '月を設定してください。',
  'Deleted message is fail': 'メッセージを削除できませんでした',
  'Password at least number, uppercase and lowercase letter, and at least 8':
    '少なくとも数字、大文字と小文字、および8文字以上のパスワード',
  'Are you sure to return this message to draft?': '下書きに戻してもよろしいでしょうか',
  'Location exceed maximum': 'ロケーションが多すぎます。20以下を選択してください。',
  'File must be Image Format!': 'イメージファイルをアップロードしてください。',
  English: '英語',
  Japanese: '日本語',
  Korean: '韓国語',
  Vietnamese: 'ベトナム語',
  'Chinese(Simplified)': '中国語簡体字',
  'Simplified Chinese': '簡体字',
  'Traditional Chinese': '繁体字',
  French: 'フランス語',
  German: 'ドイツ語',
  Spanish: 'スペイン語',
  Italian: 'イタリア語',
  Portuguese: 'ポルトガル語',
  Russian: 'ロシア語',
  Thai: 'タイ語',
  Indonesian: 'インドネシア語',
  Arabic: 'アラビア語',
  Malay: 'マレー語',
  Hindi: 'インド言語',
  Tagalog: 'タガログ語',
  Dutch: 'オランダ語',
  'At least 8 characters': '8文字以上',
  'Include number': '数字を含む',
  'Include upper character': '大文字を含む',
  'Invalid start time. Please try again.': '無効な開始時間です。やり直してください。',
  'This message is rollbacked': '下書きに戻りました。',
  'This message has been cancelled': 'メッセージはキャンセルされました。',
  'Missing default language': 'デフォルトの言語がありません。',
  'Invalid attachment': '無効な添付ファイルです。',
  'Invalid UVcode link': '無効な音声コードです。',
  'Invalid gender': '無効な性別です。',
  'Number location is too large, must <= 20':
    'ロケーションが多すぎます。20箇所以下で選んでください。',
  'Invalid app target': '無効な配信先のアプリです。',
  'Invalid ending time': '無効な終了日です。',
  'Invalid daily schedule': '無効な毎日のスケジュールです。',
  'Invalid weekly schedule': '無効な毎週のスケジュールです。',
  'Invalid repeat number': '無効な繰り返し回数です。',
  'Invalid weekday': '無効な曜日です。',
  'Invalid monthly schedule': '無効な毎月のスケジュールです。',
  'Invalid day of month': '無効な日付です。',
  'Invalid month': '無効な月です。',
  'Not support this frequency': 'この繰り返しには対応していません。',
  'Push message currently disabled': '現在配信できません。',
  'Invalid starting_time': '無効な開始時間です。',
  Information: '情報',
  'Token is expired. Are you login again?':
    'トークンの有効期限が切れています。もう一度ログインしてください。',
  'Add attachment': '添付追加',
  ja: '日本語',
  en: '英語',
  ko: '韓国語',
  zh: '繁体字',
  pdf: 'PDF',
  picture: '画像',
  url: 'URL',
  URL: 'URL',
  '(lat,lon) must not be emtpy': '(経度,緯度) を入力してください。',
  '(lat,lon) must be number': '(経度,緯度) を数字で入力してください。',
  '1st category must not be empty': 'カテゴリー１ を選択してください。',
  'Category must be number': 'カテゴリーを数字で入力してください。',
  'Point name must not be empty': 'ポイント名を入力してください。',
  '1st language must not be emtpy': '言語コード1 を入力してください。',
  'Code name must not be empty': 'コード名を入力してください。',
  'Navi Type should be number': 'ナビ種別を数字で入力してください。',
  Categories: 'カテゴリー',
  'Push billing': '通知メッセージ利用料',
  'Push option': '通知メッセージを利用する',
  Price: '金額',
  'Current month': '今月',
  'Next month': '来月',
  'Auto sending': '自動配信',
  'Auto sending option': '自動配信する',
  'Code billing': 'コード作成分',
  'Code pricing plan': 'コード作成料金プラン',
  Subscription: '月額固定',
  'Pay as you go': '従量制',
  Issued: '発行済み',
  Amount: '利用料',
  'Push type': 'メッセージ配信種類',
  'Message pricing plan': '通知メッセージ料金プラン',
  Tax: '消費税',
  'Show demo': 'デモコードを表示',
  'File must be PDF Format!': 'PDFファイルをアップロードしてください。',
  'PDF must smaller than 10MB!': '10MB以下のPDFをアップロードしてください。',
  RemainPush: '今月の配信可能な残りの通知メッセージ数は「{count}」です。',
  'Data amount is too large, could not store in symbol of specified symbol size':
    '文字数が多すぎます。コードサイズを変更して再度お試しください。',
  'Download invoice': 'ダウンロードする',
  'Account deactivated': '無効なアカウントです。',
  "Sorry, you haven't paid the bill of last month so you cannot use service. Please pay the bill for continue using our service":
    '先月のお支払い期限を過ぎてもお支払いを確認することができませんでしたので、本サービスを利用できなくになります。',
  Logout: 'ログアウト',
  Comma: '、',
  'You reached number of pushes in month. Other messages which are in sending status will not be pushed until next month.':
    '配信メッセージ数制限に達しました。未配信のスケジュールされたメッセージは来月に配信しますので、ご注意ください。',
  'Please upload the BMP file!': 'BMPファイルをアップロードしてください。',
  'Data is not format!': 'アップロードされたファイルにはコードデータがありません。',
  'Using push': '通知メッセージ利用有無',
  'Max push per month': '毎月の最大配信メッセージ数',
  'Are you sure to leave this code detail?': 'このページから移動しますか？',
  'The changed content will not be save.':
    'このページを離れると、未保存の内容は破棄されますのでご注意ください。',
  'No, cancel': 'キャンセル',
  invalidData: 'データに誤りがあります。',
  'Title max 240 length': '本文を240字以内で入力してください',
  'Copy completed': 'コピーしました。',
  'Copy failed': 'コピーできませんでした。',
  Copy: 'コピー',
  Link: 'リンク',
  'TextLengthExceededException: Maximum text length has been exceeded':
    'Pollyは{number}文字以上の内容を読み上げられません。',
  'InvalidSsmlException: Invalid SSML request': '無効なSSML',
  '5000 characters limit exceeded.':
    'Cloud Text-to-Speechは{number}文字以上の内容を読み上げられません。',
  'This project header is updated.': 'ヘッダーを更新できました。',
  'This project header update failed.': 'ヘッダーを更新できませんでした。',
  'Please input your header': 'ヘッダーを入力してください。',
  'Please input your footer': 'フッターを入力してください。',
  'Embed code': '埋め込みコード',
  Options: 'オプション',
  'Show header': 'ヘッダーを表示',
  'Show footer': 'フッターを表示',
  'Font color setting': 'フォントの色設定',
  'Color selection': '色の選択',
  'Background color setting': '背景色の設定',
  Private: '非公開',
  Public: '公開',
  'Edit the header': 'ヘッダーを編集する',
  'Edit the footer': 'フッターを編集する',
  'This code is public.': 'このコードは公開です。',
  'This code is private.': 'このコードは非公開です。',
  'This code public failed.': 'このコードを公開できませんでした。',
  'This code private failed.': 'このコードを非公開にできませんでした。',
  'This project is public.': 'このプロジェクトは公開です。',
  'This project is private.': 'このプロジェクトは非公開です。',
  'This project public failed.': 'このプロジェクトを公開できませんでした。',
  'This project private failed.': 'このプロジェクトを非公開にできませんでした。',
  Security: 'セキュリティ',
  'This project is private now, you need to change it to public to edit. Are you sure to change it to public?':
    'このプロジェクトは現在、非公開に設定されています。ソースコードを編集して公開するには設定を公開に変更する必要があります。変更してよいですか？',
  'This code is private now, you need to change it to public to edit. Are you sure to change it to public?':
    'このコードは現在、非公開に設定されています。ソースコードを編集して公開するには設定を公開に変更する必要があります。変更してよいですか？',
  'Do you also want to set all codes belong to this project to Public?':
    'このプロジェクトの全てのコードについても公開設定にしますか？',
  'Do you also want to set all codes belong to this project to Private?':
    'このプロジェクトの全てのコードについても非公開設定にしますか？',
  'Publish URL': '公開URL',
  Open: '開く',
  'This project setting is updated.': 'プロジェクトの設定が更新できました。',
  'This project setting update failed.': 'プロジェクトの設定が更新できませんでした。',
  'This code setting is updated.': 'コードの設定が更新できました。',
  'This code setting update failed.': 'コードの設定が更新できませんでした。',
  'Item order': 'アイテムの並び順',
  'New arrival order': '新しい順',
  'Oldest first': '古い順',
  'ID DESC': 'IDの降順',
  'ID ASC': 'IDの昇順',
  'User setting': 'ユーザー設定',
  'Page language': 'ページの言語',
  'Show created date': '作成日を表示',
  'Show share button': '共有ボタンを表示',
  'Show category': 'カテゴリーで検索を有効にする',
  'Show category tag': 'カテゴリタグを表示',
  'You can input a custom title for the filter category': '例：製品カテゴリーで探す',
  'Category List': 'コードカテゴリを選択',
  'Select or input custom category': 'カスタムカテゴリを選択または入力してください。',
  'Custom title': 'カスタムタイトル',
  'Search category by': '検索方法',
  'Mega menu': 'メガメニュー',
  'Scroll down select': 'スクロールダウンメニュー',
  'Search section': '検索設定',
  'Display keyword search': 'キーワードで検索を有効にする',

  'Add Root Category': 'ルートカテゴリを追加',
  'Do you want to delete this Category?': 'このカテゴリを削除します。よろしいでしょうか？',
  Warning: '警告',
  'confirm delete': 'このカテゴリにはコードがあります。| 削除してもよろしいでしょうか？',
  'Delete failed!': '削除できませんでした。',
  'Parent category must have at least 1 item!':
    '母カテゴリには、少なくとも1つの子カテゴリが必要です。',
  'New node': '新しい親カテゴリ',
  'New leaf': '新しい子カテゴリ',
  'Add Tree Node': '親子カテゴリの追加',
  'Add Leaf Node': '子カテゴリの追加',
  editCategory: '編集',
  deleteCategory: '削除',
  'Must be 3 levels maximum!': '3レベルまで作成してください。',
  'Must be 50 item maximum!': 'ルートカテゴリーは最大50個まで作成できます',
  'Background color for root category': 'ルートカテゴリの背景色',
  'Background color for sub category': 'サブカテゴリの背景色',
  'Font color for root category': 'ルートカテゴリのフォント色',
  'Font color for sub category': 'サブカテゴリのフォント色',
  'Format created date': '作成日のフォーマット',
  'PC Preview': 'PC版',
  'Mobile Preview': 'モバイル版',
  'Search by category': 'カテゴリを選択',
  'Attached URL': '添付するリンク(URL)',
  '(If you set up a link, this link will be displayed as「More details」in the Uni-Voice Mobile app.)':
    '(リンクを設定するとUni-Voiceスマーフォンアプリでこのリンクが「詳細はこちら」として表示されます。)',
  warningContentLimit:
    '音声コードに格納できる最大文字数を超過しているため途中までをコードに格納します。| アプリでスキャンした時にインターネットに接続している場合は全文を読み上げますが、オフラインの場合は、コードに格納された文字数のみが読み上げられます。',
  'Preview the content stored in the code': 'コードに格納されるテキストを表示する',
  'No options available.': 'カテゴリがありません。',
  'You can input a custom page title for the page':
    'このページのタイトルテキストを設定してください',
  'Page title': 'ページのタイトル',
  'Show title': 'タイトルを表示',
  'Page title setting': 'ページタイトルの設定',
  'Show background color': '背景を表示',
  'Show border': '枠線を表示',
  'Show print button': '印刷ボタンを表示',
  'This project is private now, you need to change it to public to embed. Are you sure to change it to public?':
    'このプロジェクトは現在、非公開に設定されています。ソースコードを埋め込んで公開するには設定を公開に変更する必要があります。変更してよいですか？',
  Restore: '復元',
  'Archived projects': 'アーカイブ',
  'Archived project list': 'アーカイブしたプロジェクト',
  'Archived code list': 'アーカイブした コード一覧',
  'Archived codes': 'アーカイブしたコード',
  archiveConfirm:
    'このプロジェクトをアーカイブしてよろしいでしょうか? | この{count}個のプロジェクトをアーカイブしてよろしいでしょうか?',
  restoreConfirm:
    'このプロジェクトを復元してよろしいでしょうか？| この{count}個のプロジェクトを復元してよろしいでしょうか?',
  ssmlIntroduction:
    'Speech Synthesis Markup Language (SSML) タグを使用すると、音声の選択、単語の発音の変更、休止の追加など、高度な読み上げ設定ができます。',
  'Advanced speak(SSML)': '高度な読み上げ(SSML)',
  'SSML Enable': 'SSMLを使用して、高度な読み上げを指定します。',
  Text: 'テキスト',
  'Alt texts': '画像の代替テキスト',
  'Attached photo': '添付する画像',
  '(If you set an image, it will be displayed as a thumbnail image of Reading Homepage)':
    '(画像を設定すると読み上げるホームページのサムネイル画像として表示されます。)',
  'You can upload up to 10MB per file': 'アップロードできるのは、1ファイルあたり最大10MBまでです',
  'Photo alt text is required': '画像の代替テキストが必要です。',
  'Publish info': '公開情報',
  'Page template': 'ページのテンプレート',
  'News(Card list)': 'ニュース（カードリスト）',
  'News(Card view)': 'ニュース（グリッド）',
  'News(List view)': 'ニュース（リスト）',
  'News(Category)': 'ニュース(カテゴリ)',
  'Page option': 'ページのオプション',
  'Item display option': 'アイテム表示のオプション',
  'Show thumbnail': 'サムネイルを表示',
  'Item naming': 'アイテム単位の名称',
  'Search option': '検索のオプション',
  'Display category search': 'カテゴリーで検索を有効にする',
  'Thumbnail type': 'サムネイルの種別',
  'Photo, image': '画像',
  'Sub directory name': 'サブディレクトリ名',
  'This project footer is updated.': 'プロジェクトのフッターは更新できました。',
  'This project footer update failed.': 'プロジェクトのフッターは更新できませんでした。',
  'This project sub directory name is updated.':
    'プロジェクトのサブディレクトリ名を更新できました。',
  'This project sub directory name update failed.':
    'プロジェクトのサブディレクトリ名を更新できませんでした。',
  'This project sub directory name is already registered.':
    'このサブディレクトリ名はすでに登録されました。',
  'Click or drag file to this area to upload':
    'ここにファイルをドロップまたはクリックしてアップロードします。',
  'Please input your image url': '画像のURLを入力します。',
  'Image URL is invalid.': '画像URLは無効です。',
  'By URL': 'URL',
  'Search setting': '検索方法の設定',
  'Color setting': '色の設定',
  'Created date setting': '作成日の設定',
  'Thumbnail setting': 'サムネイルの設定',
  'Category setting': 'カテゴリー設定',
  'View category setting': 'カテゴリーの設定を見る',
  'Header and Footer setting': 'ヘッダーとフッターの設定',
  'Page display setting': 'ページの表示設定',
  'Publish URL setting': '公開URLの設定',
  'Original domain setting': '独自ドメイン設定',
  'Examples: news, products, tickets ...': '例：ニュース , 製品 , チケット …',
  '*View past versions': '*過去のバージョンを表示',
  'Versions history': '履歴',
  'TTS player option': '音声読み上げ画面のオプション',
  'Title setting': 'タイトルの設定',
  'Color setting of texts area': 'テキストエリアの色設定',
  'TTS player display setting': '音声読み上げ画面の表示設定',
  'Page title color': 'ページのタイトル',
  'Background color': 'ページの背景',
  'Heading color': '見出し',
  'Item title color': 'アイテムのタイトル',
  'Font color for search title': '検索タイトルのフォント色',
  Photo: '写真',
  'Please input your project description': 'プロジェクトの記述を入力してください。',
  Category: 'カテゴリー設定',
  'Advanced information': '付加情報',
  'Basic information': '基本情報',
  'JAN Codes': 'JANコード',
  '(Optional)': '(任意)',
  '(The JAN code is displayed on products as a distribution code for products and is used in POS systems that are widely used mainly in convenience stores and supermarkets.)':
    '(JANコードは、商品用の流通コードとして商品などに表示され、コンビニエンスストアやスーパーを中心に広く利用されているPOSシステムで活用されています。)',
  'Item display setting': 'アイテムの表示設定',
  'Title color': 'タイトル色',
  '(If you register a JAN code as a voice code, the Uni-Voice Mobile App will scan the JAN code then display and read out the contents of this voice code)':
    '(音声コードにJANコードを登録すると、Uni-VoiceスマーフォンアプリでJANコードをスキャンしてこの音声コードの内容を表示して読み上げることができます。)',
  'Delete selected code': '選択されたコードを削除',
  'Import all texts as a single Voice code': 'ファイル全体をひとつの音声コードとしてインポートする',
  'Import texts per page as multiple Voice codes':
    'ページごとに複数の音声コードとしてインポートする',
  'Import texts with delimiter texts as multiple Voice codes':
    '区切り文字を指定して複数の音声コードとしてインポートする',
  'Delimiter list': '区切り文字一覧',
  'New delimiter': '新しい区切り文字',
  'Compose new message': '通知メッセージを作成',
  'Page icon setting': 'ページアイコンの設定',
  'Icon image (favicon)': 'アイコン画像（ファビコン）',
  '*You can enter "xx" for dynamic string replacement. Example: [String xx], [xx String], [String xx String]':
    "コンマや記号などの区切り文字を指定してください。'xx'をワイルドカードとして指定することもできます。例：【xxページ】を区切り文字に指定すると【1ページ】【2ページ】…を含む行で音声コードが分割されます。",
  'Order same as display order in code list screen': 'コード一覧画面の表示順で並べ替えます',
  'Import failed!': 'インポートできませんでした',
  'Link to Voice Code': 'このメッセージの音声コードを見る',
  'Link to messages': '作成した通知メッセージを見る',
  'Switch public/private': '公開設定',
  'Set advanced information': '付加情報設定',
  'Category hierarchy': 'カテゴリー階層',
  'You can set category hierarchy to display': '表示するカテゴリーの階層を選択することができます',
  'Exclude delimiter': '区切り文字はインポートしない',
  'Jancode is already exists': 'JANコードが既に他の音声コードに登録されています。',
  'Default Header': 'デフォルトのヘッダー',
  'Default Footer': 'デフォルトのフッター',
  'The default header is updated.': 'デフォルトヘッダーが更新できました。',
  'The default footer is updated.': 'デフォルトフッターが更新できました。',
  'The default header update failed.': 'デフォルトヘッダーが更新できませんでした。',
  'The default footer update failed.': 'デフォルトフッターが更新できませんでした。',
  'Header&Footer Setting': 'ヘッダーとフッターの設定',
  'setting-header-footer': 'ヘッダーとフッターの設定',
  "In case of active, this default header will be used for the new page of this account, otherwise it will use the system's default header contents.":
    '有効にすると、このヘッダーがこのアカウントの新しいページに使用されます。無効の場合はシステムのデフォルトが適用されます。',
  "In case of active, this default footer will be used for the new page of this account, otherwise it will use the system's default footer contents.":
    '有効にすると、このフッターがこのアカウントの新しいページに使用されます。無効の場合はシステムのデフォルトが適用されます。',
  'This default header is activated.': 'このデフォルトのヘッダーが有効化されています。',
  'This default header is de-activated.': 'このデフォルトのヘッダーは無効化されています。',
  'This default footer is activated.': 'このデフォルトのフッターは有効化されています。',
  'This default footer is de-activated.': 'このデフォルトのフッターは無効化されています。',
  'Include all codes inside the project?': 'プロジェクトに含まれるすべてのコードを含める',
  'Duplicate Project': 'プロジェクトを複製する',
  'Duplicate project successfully': 'プロジェクトを複製できました。',
  'Duplicate project failed': 'プロジェクトを複製できませんでした。',
  'Are you sure delete these codes?': '{count}個のコードを削除してよろしいですか',
  'Delete selected': '選択したコードを削除',
  'Delete all': '全てのコードを削除',
  'Are you sure delete all codes?': '全てのコードを削除してよろしいですか',
  'Reload list': 'リストをリロードする',
  'You cannot import project in this file.': 'このファイルのプロジェクトをインポートできません。',
  'Import project': 'プロジェクトをインポート',
  'Export project': 'プロジェクトをエスクポート',
  'Aspect ratio of thumbnail': '画像の縦横比',
  'Keep original ratio': '元の縦横比を維持',
  'Apply fixed ratio': '固定の縦横比を使用',
  'Importing the project succeed.': 'プロジェクトをインポートできました。',
  'Importing the project failed.': 'プロジェクトをインポートできませんでした。',
  'Importing... Please wait for a little bit!': 'インポート中です。少々お待ちください',
  'Exporting... Please wait for a little bit!': 'エスクポート中です。少々お待ちください',
  'Exporting the project succeed.': 'プロジェクトをエスクポートできました。',
  'Exporting the project failed.': 'プロジェクトをエスクポートできませんでした。',
  'Exporting the page succeed.': 'ページをエスクポートできました。',
  'Exporting the page failed.': 'ページをエスクポートできませんでした。',
  'Copy code to page successfully': 'コードを{name}ページにコピーしました。',
  'Move code to page successfully': 'コードを{name}ページに移動しました。',
  'Copy page to project successfully': 'ページをプロジェクトにコピーしました。',
  'Move page to project successfully': 'ページをプロジェクトに移動しました。',
  'You can not choose the default project!': 'デフォルトのプロジェクトは選択できません',
  'Choosing Project': 'プロジェクトの選択',
  Move: '移動',
  'This code is private now, you need to change it to public to embed. Are you sure to change it to public?':
    'このコードは現在、非公開に設定されています。ソースコードを埋め込んで公開するには設定を公開に変更する必要があります。変更してよいですか？',
  'Category management': 'カテゴリーの管理',
  Portals: 'ポータル',
  Pages: 'ページ',
  'Page name': 'ページ名',
  'Page compose': 'ページの作成',
  'Import page': 'ページのインポート',
  'Page list': 'ページ一覧',
  'Edit page': 'ページの編集',
  'Please input your page name': 'ページ名を入力してください',
  'Please input your page description': 'ページの説明を入力してください',
  'Page name max length 50': 'ページ名を50字まで入力してください。',
  'This page has been updated': 'このページは更新できました',
  'This page update failed': 'このページは更新できませんでした。',
  'This project has been updated': 'このプロジェクトは更新できました',
  'This project update failed': 'このプロジェクトは更新できませんでした。',
  'Do you also want to set all codes belong to this page to Public?':
    'このページに属するすべてのコードも公開に設定しますか',
  'Do you also want to set all codes belong to this page to Private?':
    'このページに属するすべてのコードを非公開に設定しますか',
  'Duplicate page successfully': 'ページの複製に成功しました',
  'Duplicate page failed': 'ページの複製に成功できませんでした。',
  'Do you want to duplicate this page?': 'このページを複製しますか',
  'Do you want to change the status of this page to private?':
    'このページのステータスを非公開に変更しますか',
  'Do you want to change the status of this page to public?':
    'このページのステータスを公開に変更しますか',
  'Importing the page succeed.': 'ページのインポートできました。',
  'Importing the page failed.': 'ページのインポートできませんでした。',
  'Page detail': 'ページの詳細',
  Code: 'コード',
  'Portal list': 'ポータルサイト一覧',
  'Compose portal': 'ポータルサイトの作成',
  'Portal name': 'サイト名',
  'Website editor': 'サイトエディタ',
  'Item description color': 'アイテムの説明文',
  'Website configure': '表示するサイトの設定',
  'Add new category': '新しいカテゴリーを追加',
  'Category name': 'カテゴリ名',
  'Please input category name!': 'カテゴリ名を入力してください',
  'This portal setting is updated.': '設定を変更しました。',
  'This portal setting update failed.': '設定を変更できませんでした。',
  'This portal header is updated.': 'ヘッダーが更新できました。',
  'This portal header update failed.': 'ヘッダーが更新できませんでした。',
  'This portal footer is updated.': 'フッターが更新できました。',
  'This portal footer update failed.': 'フッターが更新できませんでした。',
  'This portal sub directory name is updated.': 'ポータルのサブディレクトリ名が更新されました。',
  'This portal sub directory name is already registered.':
    'このサブディレクトリ名はすでに登録されています。',
  'Category configure': 'カテゴリーの表示設定',
  'Internal site': '読み上げるホームページ',
  'External site': '外部のサイト',
  'Website setting': 'サムネイルの設定',
  'Category text color': 'カテゴリのテキスト色',
  'Description color': '記述色',
  'Show description': '説明文を表示',
  'Mega menu setting': 'メガメニューの設定',
  Close: '閉じる',
  Create: '作成する',
  'List items': 'このカテゴリーに表示するサイト',
  'Adding Sites': 'サイトの追加',
  'Select all': 'すべてを選択する',
  'Edit site': 'サイトの編集',
  'Archived pages': 'アーカイブしたページ',
  'Codes title': 'コード',
  'Archived page list': 'アーカイブしたページ一覧',
  'Please input your portal description': 'プロジェクトの説明を入力してください。',
  'Portal site for reading homepage': '視覚に障害をお持ちのかたへのポータルサイト',
  Site: 'サイト',
  'Clear form data': 'クリア',
  Add: '追加',
  'Portal compose': 'ポータルサイトの作成',
  'Please input your portal site name!': 'サイトの名称を入力してください。',
  'Portal detail': 'ポータルの詳細',
  Lexicons: 'レキシコン',
  'Choosing lexicon will enable customize the pronunciation of words when reading content':
    'レキシコンを選択することで、コンテンツを読む際に単語の発音をカスタマイズすることができます。',
  'The sites have been added!': 'サイトが追加されました。',
  'Adding sites failed!': 'サイトが追加されませんでした。',
  'This site has been updated!': 'サイトを更新できました。',
  'Updating site failed!': 'サイトを更新できませんでした。',
  'This category has been updated!': 'カテゴリー名を更新できました。',
  'Updating category failed!': 'カテゴリー名を更新できませんでした。',
  'Are you sure to delete this site?': 'このサイトを削除してよろしいですか',
  'Please input your category name!': 'カテゴリ名を入力してください。',
  'Please input your description!': '説明を入力してください。',
  'Please input your url!': 'URLを入力してください。',
  'Please input your title!': 'タイトルを入力してください',
  'Compose page title': 'ページの作成',
  'Compose page': '作成する',
  'Save page': '保存する',
  'This page is not public, so that it can not be shown in the portal after picking up':
    'このページは公開されていないため、ポータルサイトに表示されません。',
  'Do you want to jump to the copy(move) destination?':
    '実行後にコピー先に遷移しますか？|実行後に移動先に遷移しますか？',
  'Move to category': '他のカテゴリーに移動',
  'Crawl index ASC': 'クロールインデックス ASC',
  'Crawl index DESC': 'クロールインデックス DESC',
  'Click to copy': 'クリックしてコピーします',
  PORTAL: 'ポータル',
  CATALOG: 'カタログ',
  'Include all page inside the project?': 'このプロジェクトの全てのページを含めますか？',
  'Include all codes inside each page of the project?': 'コードを含めますか？',
  'This page is private now, you need to change it to public to embed. Are you sure to change it to public?':
    'このプロジェクトは非公開です。公開ステータスに変更しますか？',
  'Duplicate page': 'ページを複製する',
  PRIMARY: 'トップページ',
  NONE: '子ページ',
  'Change Page': '保存する',
  'Home page link': 'ホームページへのリンク',
  'Sub Domain': 'サブドメイン',
  'Please input your sub domain': 'サブドメインを入力してください',
  'View page list': 'ページ一覧を見る',
  'This sub domain is already exist!': 'このサブドメインは既に使われています。',
  'Select the template for your primary page. You can change the template later.':
    'トップページのテンプレートを選択してください。後で変更することもできます。',
  'A Catalog page is a collection of codes that can be used to build a website or application. It is a great way to start a project.':
    'カタログページではニュースやイベント、製品などの一覧を読み上げるホームページの形式で表示できます。',
  'A portal is a collection of pages that can be used to build a website or application.':
    'ポータルページでは読み上げるホームページや外部のWebサイトへのをリンクを一覧で表示できます。',
  'Include all codes inside the page?': 'コードを含めますか？',
  'Select the type of page you want to create. Additional configuration and integrations can be added later.':
    '作成するページのテンプレートを選択してください。',
  'Set as primary': 'トップページに設定',
  'Set as primary successfully.': 'トップページに設定しました。',
  'Are you sure to change the primary page?': 'トップページに設定していいですか？',
  'Page introduction': 'ページの説明',
  'Show introduction button': '「このサイトについて」ボタンを表示',
  'Please enter the description for this site': 'このサイトについての説明文を入力してください',
  'Introduction content': '説明文',
  'Switch page': 'ページの切替',
  'Export page': 'ページのエクスポート',
  'This page is public.': 'このページは公開ステータスです。',
  'This page is private.': 'このページは非公開ステータスです。',
  'This page public failed.': 'ステータスを変更できませんでした。',
  'This page private failed.': 'ステータスを変更できませんでした。',
  'This page setting is updated.': '設定を変更しました。',
  'This page setting update failed.': '設定を変更できませんでした。',
  'Created page successfully.': 'ページを作成しました。',
  'Select destination page': 'コピー先のページを選択|移動先のページを選択',
  'Select destination project': 'コピー先のプロジェクトを選択|移動先のプロジェクトを選択',
  'Archive successfully': 'アーカイブできました。',
  'Archive failed': 'アーカイブできませんでした。',
  'Restore successfully': '復元できました。',
  'Restore failed': '復元できませんでした。',
  'Sub domain must be string!': 'サブドメインは文字列で入力してください。',
  'Go to edit sub domain': 'サブドメインを編集する',
  'How to add a new category': '新しいカテゴリーの追加方法',
  'Add to the beginning': '先頭に追加する',
  'Add to the end': '末尾に追加する',
  Catalog: 'カタログ',
  Portal: 'ポータル',
  'Text align': '表示位置',
  Left: '左',
  Center: '中心',
  Right: '右',
  'Font size': 'フォントサイズ',
  'Menu setting': 'ページメニューの表示方法',
  'Show menu': 'メニューを表示',
  'Page logo setting': 'ページロゴの設定',
  'Show logo': 'ロゴを表示',
  'Logo image': 'ロゴの画像',
  'Logo caption': 'ロゴの見出し',
  'Show page title': 'ページタイトルを表示',
  'Do you want to archive the selected page?': '選択したページをアーカイブしますか？',
  'Do you want to restore the selected page?': '選択したページを復元しますか？',
  'Primary page cannot be archived.': 'トップページはアーカイブできません。',
  'Primary page cannot be moved.': 'トップページは移動できません。',
  'This slug is already registered': 'このページのサブディレクトリ名は既に登録されています。',
  'This page sub directory name is updated.': 'このページのサブディレクトリ名を更新しました。',
  'Search page by name': 'ページ名で検索',
  'Do you want to change the status of this project to public?':
    'このプロジェクトのステータスを公開に変更しますか？',
  'Do you want to change the status of this project to private?':
    'このプロジェクトのステータスを非公開に変更しますか？',
  'This action will change the visibility of all pages in this project. Are you sure you want to continue?':
    'この操作はこのプロジェクトの全てのページの公開ステータスを変更します。よろしいですか？',
  'This action will change the visibility of all pages in this project.':
    'この操作はこのプロジェクトの全てのページの公開ステータスを変更します。',
  'This action will change the visibility of the parent project of this page to public. Do you want to continue?':
    'この操作はこのページの親プロジェクトの公開ステータスを変更します。よろしいですか？',
  'Include all codes inside this page?': 'コードを含めますか？',
  'X-Small': '極小',
  Small: '小',
  Medium: '中',
  Large: '大',
  'X-Large': '極大',
  'Icon view': 'アイコンで表示',
  'Menu view': 'メニューで表示',
  jpn: '日本語',
  eng: '英語',
  chi: '簡体字',
  zho: '繁体字',
  kor: '韓国語',
  fre: 'フランス語',
  ger: 'ドイツ語',
  spa: 'スペイン語',
  ita: 'イタリア語',
  por: 'ポルトガル語',
  rus: 'ロシア語',
  tai: 'タイ語',
  vie: 'ベトナム語',
  ind: 'インドネシア語',
  ara: 'アラビア語',
  may: 'マレー語',
  hin: 'インド言語',
  tgl: 'タガログ語',
  dut: 'オランダ語',
  'Create code content': 'コードの内容を作成',
  'Plain Text version': 'プレーンテキスト',
  'This content is embedded into Univoice Code and will be used in no internet case':
    'このテキストは音声コードに埋め込まれ、オフラインの場合にUni-Voiceアプリケーションに表示されます。',
  'HTML Rich version': 'ウェブコンテンツ(HTML)',
  'This content is kept in Univoice Backend and will be downloaded to display when the user scans the code':
    'このウェブコンテンツ(HTML)はオンラインの場合にUni-Voiceアプリケーションに表示されます。',
  'Do you want to revert the previous version?': '前のバージョンに戻してもよろしいですか？',
  'Old version': '古いバージョン',
  'New version': '新しいバージョン',
  'Previous version': '前のバージョン',
  Revert: '戻す',
  'Preview code content': 'プレーンテキスト版のプレビュー',
  'Preview previous version': '前の版をプレビュー',
  'Create from scratch': '最初から作成する',
  'Create from an existing site': '既存のウェブサイトから作成する',
  'Pick by browser': 'ブラウザで選ぶ',
  'The input is not valid URL!': 'URLの形式が正しくありません',
  'Import file or input url': 'HTMLファイルをインポートするまたはURLを入力する',
  'Please input your URL!': 'URLを入力してください',
  'Would you like to limit the scope of content in the original site instead of taking it all?':
    '元サイトのHTMLコンテンツを全て取り込むのではなく、範囲を限定しますか？',
  'This is a feature that allows you to limit the scope of content in the original site instead of taking it all.':
    'これは、既存のサイトから取り込むHTMLコンテンツの範囲を限定するための機能です。',
  'Which needs Chrome Extension to enable. Please access':
    'この機能を有効にするには、ChromeブラウザおよびChrome 拡張機能',
  'to install if not yet.': 'のインストールが必要です。',
  Or: 'または',
  'Input url': 'URLを入力する',
  'Imported content!': 'コンテンツの取り込み完了',
  'Preview content': 'プレビューを見る',
  'Pickup content': 'コンテンツの取り込み',
  'Picked up content successfully!': 'コンテンツを取り込みできました',
  "Can't pick up the content from this site!": 'コンテンツを取り込みできませんでした',
  "Don't ask me again": 'このダイアログを再び表示しない',
  'Containing catalog pages': 'カタログページの公開設定',
  'Include all codes inside the catalog pages?': 'コードを含めますか？',
  'This action will change the visibility of the parent project of selected pages to public. Do you want to continue?':
    'この操作は選択したページの親プロジェクトの公開ステータスを変更します。よろしいですか？',
  'This action will change the visibility of all pages in selected projects. Are you sure you want to continue?':
    'この操作は選択したプロジェクトの全てのページの公開ステータスを変更します。よろしいですか？',
  'Pin to top': '先頭に固定する',
  Unpin: '先頭に固定を解除',
  'This page is pinned or primary page. You can not drag and drop it.':
    'このページは固定されているか、トップページです。ドラッグアンドドロップできません。',
  'Codes are created in the order shown. You can change the order by dragging.':
    'コードは表示されている順番に作成されます。ドラッグして順番を変更できます。',
  'Uni-Voice': 'Uni-Voice',
  'Open link': 'リンクを開く',
  'This page is private now, you need to change it to public to edit website!':
    'このページは非公開です。公開に変更してから編集してください。',
  'Item background': 'アイテムの背景',
  'Preview rich content': 'ウェブコンテンツ版のプレビュー',
  'Import category': 'カテゴリーをインポート',
  'Importing category successfully.': 'カテゴリーをインポートしました。',
  'Importing category failed.': 'カテゴリーのインポートに失敗しました。',
  'Export category': 'カテゴリーをエクスポート',
  'Exporting category successfully.': 'カテゴリーをエクスポートしました。',
  'Exporting category failed.': 'カテゴリーのエクスポートに失敗しました。',
  'Speak category tag': 'カテゴリーのタグを読み上げる',
  Fold: '折りたたむ',
  Unfold: '展開する',
  'This is an archived page': 'アーカイブされたページです。',
  'The source page is currently private!': '現在、ソースページは非公開です！',
  'Show numbering': '番号を表示',
  General: '一般',
  Schedule: 'スケジュール',
  Visible: '表示',
  Hidden: '非表示',
  Parameters: 'パラメーター',
  'Publish status': '公開ステータス',
  'Scheduling in progress': 'スケジュール設定中',
  'Scheduled for': 'スケジュール日時',
  schedulePublishChangeHTML: '{fromStatus} から {toStatus} に変更',
  'Time remaining': '残り時間',
  'Successfully scheduled': 'スケジュール実施済み',
  'Completed at': '完了日時',
  'Re-schedule': '再設定する',
  'Schedule setting': 'スケジュール設定',
  'Setup schedule': '設定する',
  'Cancel schedule': 'キャンセルする',
  'Change the publish status of all pages and codes in this project.':
    'すべてのページとコードの公開ステータスを変更する。',
  'Do not change the publish status of pages and codes (keep current settings).':
    'ページとコードの公開ステータスは変更しない（現在の設定を保持する）。',
  'SVG image is not supported!': 'SVG画像はサポートされていません',
  'Are you sure you want to use this url?': 'このURLを使用してもよろしいですか？',
  'Column setting': 'アイテムの列数の設定',
  'Type of column': '列のタイプ',
  'Number of column': '列の数',
  Fixed: '固定',
  Flexible: 'フレキシブル',
  'Please input the password': 'パスワードを入力してください',
  'Enable authentication': '認証を有効にする',
  'Password Setting': 'パスワードの設定',
  'When set flexibly, it adjusts the number of columns displayed according to the number of categories.':
    'フレキシブルに設定すると、カテゴリーの数に合わせて表示する列数を調整します。',
  'Global menu': 'グローバルメニュー',
  Tags: 'タグ',
  'Tag setting': 'タグの設定',
  'Show tag': 'タグを表示',
  'Speak tag': 'タグを読み上げる',
  'Font color for tag': 'タグのフォント色',
  'Background color for tag': 'タグの背景色',
  '(Tags are used to classify codes. It can be used for several purposes such as subcategories, search, etc.)':
    '(タグはコードを分類するために使用されます。サブカテゴリーや検索など、いくつかの目的に使用できます。)',
  'You can add tags by typing them in the box below.':
    'タグは下のボックスに入力することで追加できます。',
  'Please enter a tag': 'タグを入力してください',
  'Align category-item': 'アイテムの並べ方',
  'Vertical-Horizontal': '横方向に並べる',
  'Horizontal-Vertical': '縦方向に並べる',
  Configuration: '設定',
  Conditions: '条件',
  'Auto-push message configuration': '通知メッセージの自動送信設定',
  'Push after': '自動送信の設定',
  'Owner auto approval time': '自動承認時間',
  'Add new page condition': '新しい条件を追加',
  'Please select target page': '対象のページを選択してください',
  'Field name': 'フィールド名',
  String: '文字列',
  Array: '配列',
  Operator: '演算子',
  'Equal to': '等しい',
  Contains: '含む',
  'Similar to': '類似',
  Value: '値',
  'Save message configuration': '設定を保存する',
  'Invalid target data': '無効なターゲットデータ',
  'Invalid schedule configuration': '無効なスケジュール設定',
  'Invalid page condition': '無効なページ条件',
  Setting: '設定',
  'Enable auto-push': '自動送信を有効にする',
  'This project is not have permission to use this feature':
    'このプロジェクトはこの機能を使用する権限がありません',
  'Please contact your administrator to get more information':
    '詳細については管理者にお問い合わせください',
  'Or condition': 'OR条件',
  'minutes later': '分後',
  'Revision history': 'バージョン履歴',
  'Release this version': 'このバージョンをリリースする',
  'Optionally, you can add a note for this version for revision history.':
    'このバージョンのメモを追加できます。',
  'This version is for ...': 'このバージョンは...',
  'Revisions for {name}': '{name}のバージョン',
  'Version {version}': 'バージョン{version}',
  'Date time': '日時',
  Note: 'メモ',
  Revision: 'バージョン',
  'Current revision': '現在のバージョン',
  'Are you sure to restore this version?': 'このバージョンを復元してよろしいですか？',
  'Discard changes': '変更を破棄する',
  'Discard changes successfully.': '変更を破棄しました。',
  'Are you sure to discard changes?': '変更を破棄してよろしいですか？',
  'Save successfully.': '保存しました。',
  'Save failed.': '保存できませんでした。',
  '*This is the URL of the public version': '*公開版のURLです',
  '*This is the category configure of the public version': '*公開版のカテゴリー設定です',
  'Public version': '公開版',
  'Draft version': '下書き版',
  'This website is not public yet': 'このサイトはまだ公開されていません',
  Release: 'リリースする',
  'Please edit your website first before pubic this page.':
    'このページを公開する前に、まずサイトを編集してください。',
  'New version saved!': '新しいバージョンを保存しました。',
  'New version is released!': '新しいバージョンをリリースしました。',
  'Save and Update': '保存して更新する',
  'There are changes that have not been saved.': '保存されていない変更があります。',
  'There are changes that have not been updated.': '更新されていない変更があります。',
  'Show more': 'もっと見る',
  'Are you sure you want to add this page to the portal?':
    'このページをポータルに追加してもよろしいですか？',
  'You are trying to import a project or page that contains web content (HTML), but your account does not currently support the web content version of the voice code. Only plain text data will be imported.':
    'ウェブコンテンツ(HTML)を含むプロジェクトまたはページをインポートしようとしていますが、お使いのアカウントは現在、ウェブコンテンツ版の音声コードをサポートしていません。プレーンテキストのデータのみがインポートされます。',
  'Required only for private page': '非公開のページのみ有効',
  'Follow webhook instructions': 'Webhookの指示に従う',
  Word: '語句',
  Reading: '読み方',
  'Dictionary name': '辞書名',
  'Add XXX to dictionary': "'{key}'を辞書に追加",
  'Add to existing dictionary': '既存の辞書に追加',
  'Create new dictionary': '新しい辞書を作成',
  'New dictionary language': '新しい辞書の言語',
  'Select dictionary language': '辞書の言語を選択',
  'Input dictionary name': '辞書名を入力してください',
  'Create dictionary successfully': '辞書が正常に作成されました',
  'New Dictionary': '新しい辞書',
  'The name must follow the regular express format [0-9A-Za-z], and up to 20 characters long':
    '名前は正規表現[0-9A-Za-z]に従い、最大20文字までである必要があります',
  'Dictionary Name': '辞書名',
  'Please input dictionary name': '辞書名を入力してください',
  'Dictionary name must be less than 20 characters': '辞書名は20文字未満である必要があります',
  'Name must follow the regular express format [0-9A-Za-z]':
    '名前は正規表現[0-9A-Za-z]に従う必要があります',
  'Are you sure to delete this dictionary language?':
    'この言語の辞書をすべて削除してもよろしいですか？',
  'This action will delete all the dictionaries inside!':
    'この操作により、この言語のすべての辞書が削除されます！',
  'To confirm deletion, under input field please enter: delete':
    '削除を実行するには、入力フィールドに「delete」と入力してください',
  'Delete dictionary successfully': '辞書が正常に削除されました',
  'Dictionary setting': '辞書設定',
  'You can activate maximum 5 default items': '最大5つの辞書を設定できます',
  'Be sure to be aware of the limitation when using AWS Polly':
    'AWS Pollyを使用する際の制限に注意してください',
  'Add dictionary': '辞書を追加',
  'Are you sure to delete this dictionary?': 'この辞書を削除してもよろしいですか？',
  'Are you sure to update this dictionary?': 'この辞書を更新してもよろしいですか？',
  'Are you sure to delete?': '削除してもよろしいですか？',
  "AWS Polly Standard: It's an Amazon Web Services text-to-speech service that converts text into natural-sounding speech, offering basic customization options.":
    'AWS Polly Standard: これはAmazon Web Servicesのテキスト読み上げサービスで、テキストを自然な音声に変換し、基本的なカスタマイズオプションを提供します。',
  "AWS Polly Neural: It's an enhanced version of AWS Polly that uses neural network models for speech synthesis, resulting in more expressive and human-like speech with improved quality and pronunciation.":
    'AWS Polly Neural: これはAWS Pollyの強化版で、音声合成にニューラルネットワークモデルを使用し、より表現豊かで人間らしい音声を生成し、音質と発音が向上します。',
  'You can choose which default TTS to use for your organization.':
    '組織で使用するデフォルトのTTSを選択できます。',
  'Update dictionary successfully': '辞書が正常に更新されました',
  'Project dictionary is set already but you are trying to change it, Page dictionary is used as higher priority':
    'このプロジェクトには辞書が既に設定されています。このページに辞書を設定すると、ページに設定した辞書が優先されます。',
  'Project dictionary or Page dictionary is set already but you are trying to change it, Code dictionary is used as higher priority':
    'このプロジェクトもしくはぺージには辞書が既に設定されています。このコードに辞書を設定すると、コードに設定した辞書が優先されます。',
  'Choosing dictionary will enable customize the pronunciation of words when reading content. Maximum 5 dictionaries can be selected.':
    '辞書を設定すると、テキスト読み上げの単語の発音をカスタマイズすることができます。最大5つの辞書を選択することができます。',
  Dictionaries: '辞書',
  Dictionary: '辞書設定',
  "You can register a string in the dictionary by selecting and right-clicking on it. Strings that have already been registered in the dictionary are highlighted.": "文字列を選択して右クリックすると、その文字列を辞書登録できます。また、辞書に登録済みの文字列はハイライトされます。",
  "Are you sure to delete selected?": "選択した語句を削除してよろしいですか?",
  "Delete selected word": "選択した語句を削除",
  "Update the dictionary name successfully": "辞書名を変更できました。",
  "Update dictionary name": "辞書名を更新する"
};
