import Vue from 'vue';
import { univoiceApi } from '@/api';

const initialState = () => ({
  entities: {},
  names: []
});

const store = {
  namespaced: true,
  state: () => initialState(),
  getters: {
    names: state => state.names,
    dictionaries: (state, getters) => {
      return getters.names.map(name => state.entities[name]) || [];
    },
    dictionaryByName: state => name => state.entities[name],
    dictionaryTree: (state, getters) => {
      const dictionaries = {};
      getters.dictionaries.forEach(dic => {
        const newDictionary = {
          title: dic.name,
          value: dic.name,
          key: dic.name
        };
        if (!dictionaries[dic.language]) {
          dictionaries[dic.language] = {
            title: dic.language,
            value: dic.language,
            selectable: false,
            checkable: false,
            children: [newDictionary]
          };
        } else {
          dictionaries[dic.language].children.push(newDictionary);
        }
      });
      return Object.values(dictionaries);
    },
    dictionariesGroupByLang: (state, getters) => {
      const dictionaries = {};
      getters.dictionaries.forEach(dic => {
        if (!dictionaries[dic.language]) {
          dictionaries[dic.language] = {
            lang: dic.language,
            children: [dic]
          };
        } else {
          dictionaries[dic.language].children.push(dic);
        }
      });
      return Object.values(dictionaries);
    },
    dictionaryByLang: (state, getters) => lang => {
      const data = getters.dictionariesGroupByLang.find(item => item.lang === lang);
      return data?.children || [];
    },
    dictionaryLangs: (state, getters) => {
      return getters.dictionariesGroupByLang.map(item => item.lang);
    },
    dictionaryNamesByLang: (state, getters) => lang => {
      const data = getters.dictionariesGroupByLang.find(item => item.lang === lang);
      return data?.children?.map(item => item.name);
    }
  },
  actions: {
    async fetchDictionaries({ commit }, company_id) {
      const filter = { where: { company_id }}
      const data = await univoiceApi.dictionary.getDictionaries({ filter })
      commit('SET', data);
    },
    addDictionary({ commit }, dictionary) {
      commit('SET', dictionary);
    },
    updateDictionary({ commit }, dictionary) {
      commit('UPDATE', dictionary)
    },
    deleteDictionaryByLang({ commit }, lang) {
      commit('DELETE_BY_LANG', lang);
    },
    deleteDictionaryByNames({ commit }, names) {
      commit('DELETE_BY_NAMES', names);
    },
    updateDictionaryName({ commit }, { oldName, newName }) {
      commit('UPDATE_DICTIONARY_NAME', { oldName, newName })
    },
    reset({ commit }) {
      commit('RESET');
    }
  },
  mutations: {
    SET: (state, data) => {
      if (Array.isArray(data)) {
        let newNames = [...state.names];
        const newEntities = Object.assign({}, state.entities);

        data.forEach(value => {
          const name = value.name;
          newNames = [...newNames, name];
          newEntities[name] = Object.assign({}, newEntities[name], value);
        });

        const names = Array.from(new Set(newNames));
        const entities = Object.assign({}, newEntities);

        Vue.set(state, 'names', names);
        Vue.set(state, 'entities', Object.assign({}, entities));
      } else {
        const name = data.name;
        const newNames = Array.from(new Set([...state.names, name]));
        const newEntities = Object.assign({}, state.entities, {
          [name]: Object.assign({}, state.entities[name], data)
        });
        Vue.set(state, 'names', newNames);
        Vue.set(state, 'entities', newEntities);
      }
    },
    UPDATE: (state, data) => {
      const name = data.name;
      const newEntities = Object.assign({}, state.entities);
      newEntities[name] = data;

      Vue.set(state, 'entities', newEntities);
    },
    DELETE_BY_NAMES: (state, names) => {
      if (!names || names.length === 0) return;

      let newNames = state.names.filter(item => !names.includes(item));
      const newEntities = Object.assign({}, state.entities);
      names.forEach(name => {
        delete newEntities[name];
      });

      Vue.set(state, 'names', newNames);
      Vue.set(state, 'entities', newEntities);
    },
    UPDATE_DICTIONARY_NAME: (state, { oldName, newName }) => {
      if (!oldName || !newName) return;

      let newNames = state.names.map(name => name === oldName ? newName : name)
      const newEntities = Object.assign({}, state.entities)
      newEntities[newName] = {
        ...newEntities[oldName],
        sk: newName,
        name: newName,
      }

      delete newEntities[oldName];

      Vue.set(state, 'names', newNames);
      Vue.set(state, 'entities', newEntities);
    },
    RESET: state => {
      Object.assign(state, initialState());
    }
  }
};

export default store;
