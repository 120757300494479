import service from './request';
import { lambdaService } from './lambda';
import store from '@/store';
import { PROJECT_STATUS } from '@/enum';

const pref = require('@/data/pref.json');
const newPref = require('@/data/new-pref.json');
class UnivoiceApi {
  user: any;
  company: any;
  message: any;
  project: any;
  page: any;
  setting: any;
  billing: any;
  pushLog: any;
  code: any;
  pref: any;
  hub: any;
  sound: any;
  oauth: any;
  masterData: any;
  pageGroup: any;
  pageGroupMapping: any;
  lexicon: any;
  ragtEditor: any;
  remoteConfig: any;
  dictionary: any;

  constructor() {
    (this.pref = {
      find: () => {
        return Promise.resolve({
          pref,
          newPref
        });
      }
    }),
      (this.message = {
        count: async (companyId: string, where: any) =>
          this.call('/api/company/:companyId/messages/count', {
            method: 'get',
            routeParams: { companyId },
            urlParams: { where }
          }),
        find: async (companyId: string, urlParams: any) =>
          this.call('/api/company/:companyId/messages', {
            method: 'get',
            urlParams,
            routeParams: {
              companyId
            }
          }),
        create: async (companyId: string, data, isDraft = false) =>
          this.call('/api/company/:companyId/messages', {
            method: 'post',
            routeParams: {
              companyId
            },
            body: { ...data, status: isDraft ? 'DRAFT' : 'SUBMIT' }
          }),
        update: async (companyId: string, messageId: string, data, isDraft = false) =>
          this.call('/api/company/:companyId/messages/:messageId', {
            method: 'put',
            routeParams: {
              companyId,
              messageId
            },
            body: { ...data, status: isDraft ? 'DRAFT' : 'SUBMIT' }
          }),
        updateStatus: (companyId: string, messageId: string, status: string) =>
          this.call('/api/company/:companyId/messages/:messageId', {
            method: 'put',
            routeParams: {
              companyId,
              messageId
            },
            body: { status }
          }),
        findById: async (messageId, urlParams) =>
          this.call('/api/messages/:messageId', {
            method: 'get',
            urlParams,
            routeParams: { messageId }
          }),
        delete: (companyId, messageId) => {
          return this.call('/api/company/:companyId/messages/:messageId', {
            method: 'delete',
            routeParams: {
              companyId,
              messageId
            }
          });
        }
      }),
      (this.company = {
        requestRegistration: async (email: string) => {
          const companyTypes = (process.env.VUE_APP_COMPANY_TYPE as any).split(' ');
          const companyType = companyTypes.includes('PUSH_ONLY') ? 'PUSH_ONLY' : 'FULL_ACCESS';
          return this.call('/api/company-register', {
            method: 'post',
            body: { email, company_type: companyType }
          });
        },
        verifyRequestToken: async (token: string) =>
          this.call('/api/company-register/:token', {
            method: 'get',
            routeParams: {
              token
            }
          }),
        register: async (token, info) => {
          return this.call('/api/company-register/:token/submit', {
            method: 'post',
            routeParams: {
              token
            },
            body: { ...info, company_type: process.env.VUE_APP_COMPANY_TYPE }
          });
        }
      }),
      (this.user = {
        completeNewPassword: async ({ email, newPassword }, token: string) =>
          this.call('/api/user/replace-password', {
            method: 'post',
            urlParams: {
              access_token: token
            },
            body: { email, newPassword }
          }),
        login: async ({ email, password }) =>
          this.call('/api/user/login', {
            method: 'post',
            urlParams: {
              include: 'user'
            },
            body: { email, password }
          }),
        refreshAccessToken: async token =>
          this.call('/api/user/refresh-access-token', {
            method: 'post',
            urlParams: {
              include: 'user'
            },
            body: { token }
          }),
        me: async () =>
          this.call('/api/user/me', {
            method: 'get'
          }),
        logout: async ({ access_token }) => {
          this.call('/api/user/logout', {
            method: 'post',
            urlParams: { access_token }
          });
        },

        changePassword: async (oldPassword: string, newPassword: string) =>
          this.call('/api/user/change-password', {
            method: 'post',
            body: { oldPassword, newPassword }
          }),

        forgotPassword: async (email: string) =>
          this.call('/api/user/reset', {
            method: 'post',
            body: { email }
          }),

        forgotPasswordSubmit: async (
          email: string,
          verificationCode: string,
          newPassword: string
        ) =>
          this.call('/api/user/new-password', {
            method: 'post',
            body: { email, verificationCode, newPassword }
          }),
        generateTempToken: companyId =>
          this.call('/api/user/generate-temporary-token', {
            method: 'post',
            body: { company_id: companyId, ttl: 3600 }
          })
      }),
      (this.project = {
        count: (companyId: string, where: any) => {
          return this.call('/api/company/:companyId/campaigns/count', {
            method: 'get',
            routeParams: { companyId },
            urlParams: { where }
          });
        },
        find: (companyId: string, urlParams: any) => {
          return this.call('/api/company/:companyId/campaigns', {
            method: 'get',
            urlParams,
            routeParams: { companyId }
          });
        },
        createProject: async (companyId, payload) => {
          const response = await this.call('/api/company/:companyId/campaigns', {
            method: 'post',
            routeParams: { companyId },
            body: { ...payload }
          });
          store.dispatch('project/addProject', { payload: response });
          return response;
        },
        getProjectById: (companyId, campaignProjectId) => {
          return this.call('/api/company/:companyId/campaigns/:campaignProjectId', {
            method: 'get',
            routeParams: { companyId, campaignProjectId }
          });
        },
        editProjectById: async (companyId, campaignProjectId, input) => {
          const response = await this.call('/api/company/:companyId/campaigns/:campaignProjectId', {
            method: 'put',
            routeParams: { companyId, campaignProjectId },
            body: input
          });
          store.dispatch('project/updateProjectById', response);
          return response;
        },
        editProjectMessageConfigById: async (campaignProjectId, input) => {
          const response = await this.call('/api/campaigns/:campaignProjectId/message-config', {
            method: 'put',
            routeParams: { campaignProjectId },
            body: input
          });
          store.dispatch('project/updateProjectById', response);
          return response;
        },
        archiveProjects: async ({ ids, status }) => {
          const response = await this.call('/api/campaigns/archive', {
            method: 'post',
            body: { ids, status }
          });
          // foreach ids, remove archive project or add new in store by status
          if (status === PROJECT_STATUS.ARCHIVE) {
            response.forEach(project => {
              store.dispatch('project/removeProject', project);
            });
          }
          if (status === PROJECT_STATUS.ACTIVE) {
            store.dispatch('project/addProject', { payload: response });
          }
          return response;
        },
        duplicateProject: async (campaignProjectId, payload) => {
          const response = await this.call('/api/campaigns/:campaignProjectId/duplicate', {
            method: 'post',
            routeParams: { campaignProjectId },
            body: payload
          });
          store.dispatch('project/addProject', { payload: response });
          store.dispatch('page/fetchPagesByProject', response.id);
          return response;
        },
        exportProjectById: (campaignProjectId, options) => {
          return this.call('/api/campaigns/:campaignProjectId/export', {
            method: 'get',
            routeParams: { campaignProjectId },
            urlParams: { options }
          });
        },
        importProjectByUrl: url => {
          return this.call('/api/campaigns/import', {
            method: 'post',
            body: { url }
          });
        },
        checkExistSubDomain: (subDomain, campaignProjectId = null) => {
          const filter = { sub_domain: subDomain, campaign_id: campaignProjectId };
          return this.call('/api/campaigns/sub-domain/exist', {
            method: 'get',
            urlParams: { filter }
          });
        },
        setUpSchedule: (campaignProjectId, payload) => {
          return this.call('/api/campaigns/:campaignProjectId/schedule', {
            method: 'post',
            routeParams: { campaignProjectId },
            body: payload
          });
        },
        copyPageToAnotherProject: (projectId: string, projectIds: Array<string>) => {
          return this.call('/api/campaigns/copy-project/:campaignProjectId', {
            method: 'post',
            routeParams: {
              campaignProjectId: projectId
            },
            body: { projectIds }
          });
        },
        movePageToAnotherProject: (projectId: string, projectIds: Array<string>) => {
          return this.call('/api/campaigns/move-project/:campaignProjectId', {
            method: 'post',
            routeParams: {
              campaignProjectId: projectId
            },
            body: { projectIds }
          });
        },
        pinningProject: (campaignProjectId: string, pinStatus: boolean) => {
          return this.call('/api/campaigns/:campaignProjectId/pinning', {
            method: 'post',
            routeParams: {
              campaignProjectId
            },
            body: { pinStatus }
          });
        }
      }),
      (this.page = {
        count: (campaignProjectId: string, where: any) => {
          return this.call('/api/campaigns/:campaignProjectId/projects/count', {
            method: 'get',
            routeParams: { campaignProjectId },
            urlParams: { where }
          });
        },
        find: (campaignProjectId: string, urlParams: any) => {
          return this.call('/api/campaigns/:campaignProjectId/projects', {
            method: 'get',
            urlParams,
            routeParams: { campaignProjectId }
          });
        },
        findByCompanyId: (companyId: string, urlParams: any) => {
          return this.call('/api/company/:companyId/projects', {
            method: 'get',
            urlParams,
            routeParams: { companyId }
          });
        },
        findOneByCompanyId: (companyId: string, pageId: string) => {
          return this.call('/api/company/:companyId/projects/:pageId', {
            method: 'get',
            routeParams: { companyId, pageId }
          });
        },
        createPage: async (companyId, campaignProjectId, payload) => {
          const response = await this.call('/api/campaigns/:campaignProjectId/projects', {
            method: 'post',
            routeParams: { campaignProjectId },
            body: { ...payload, company_id: companyId }
          });
          store.commit('page/SET', { payload: response });
          store.commit('project/RESET_PROJECTS_AND_PAGES', {});
          return response;
        },
        getPageById: (campaignProjectId, pageId) => {
          return this.call('/api/campaigns/:campaignProjectId/projects/:projectId', {
            method: 'get',
            routeParams: { campaignProjectId, projectId: pageId }
          });
        },
        editPageById: async (campaignProjectId, pageId, input) => {
          const response = await this.call(
            '/api/campaigns/:campaignProjectId/projects/:projectId',
            {
              method: 'put',
              routeParams: { campaignProjectId, projectId: pageId },
              body: input
            }
          );
          return response;
        },
        getCategoriesByPortalPageId: ({ id, urlParams }) => {
          return this.call('/api/projects/:id/project_groups', {
            method: 'get',
            routeParams: { id },
            urlParams
          });
        },
        createCategoryByPortalPageId: ({ id, payload }) => {
          return this.call('/api/projects/:id/project_groups', {
            method: 'post',
            routeParams: { id },
            body: payload
          });
        },
        updateCategoryByPortalPageId: ({ pageId, categoryId, payload }) => {
          return this.call('/api/projects/:id/project_groups/:fk', {
            method: 'put',
            routeParams: { id: pageId, fk: categoryId },
            body: payload
          });
        },
        deleteCategoryByPortalPageId: ({ pageId, categoryId }) => {
          return this.call('/api/projects/:id/project_groups/:fk', {
            method: 'delete',
            routeParams: { id: pageId, fk: categoryId }
          });
        },
        archivePages: async ({ ids, status }) => {
          const response = await this.call('/api/projects/archive', {
            method: 'post',
            body: { ids, status }
          });
          store.commit('project/RESET_PROJECTS_AND_PAGES', {});
          return response;
        },
        duplicatePage: async (pageId, payload) => {
          const response = await this.call('/api/projects/:projectId/duplicate', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: payload
          });
          store.commit('page/SET', { payload: response });
          store.commit('project/RESET_PROJECTS_AND_PAGES', {});
          return response;
        },
        exportPageById: (pageId, options) => {
          return this.call('/api/projects/:projectId/export', {
            method: 'get',
            routeParams: { projectId: pageId },
            urlParams: { options }
          });
        },
        importPageByUrl: async (url, projectId) => {
          const response = await this.call('/api/projects/import', {
            method: 'post',
            body: { url, campaign_id: projectId }
          });
          store.commit('project/RESET_PROJECTS_AND_PAGES', {});
          return response;
        },
        getCodeDrafts: companyId => {
          return this.call('/api/company/:companyId/code-drafts', {
            method: 'get',
            routeParams: { companyId }
          });
        },
        getCodeDraftsById: (companyId, codeId) => {
          return this.call('/api/company/:companyId/code-drafts/:codeId', {
            method: 'get',
            routeParams: { companyId, codeId }
          });
        },
        deleteCodeDraftById: (pageId: string, codeId: string) => {
          return this.call('/api/projects/:projectId/code/:codeId', {
            method: 'delete',
            routeParams: { projectId: pageId, codeId }
          });
        },
        countCodes: (pageId: string, where: any) => {
          return this.call('/api/projects/:projectId/code/count', {
            method: 'get',
            routeParams: { projectId: pageId },
            urlParams: { where }
          });
        },
        countCodeDrafts: (companyId: string, where: any) => {
          return this.call('/api/company/:companyId/code-drafts/count', {
            method: 'get',
            routeParams: { companyId },
            urlParams: { where }
          });
        },
        getCodes: (pageId: string, urlParams: any) => {
          return this.call('/api/projects/:projectId/code', {
            method: 'get',
            urlParams,
            routeParams: { projectId: pageId }
          });
        },
        createCodes: (pageId, input) => {
          return this.call('/api/projects/:projectId/code', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        getCodeById: (pageId, codeId) => {
          return this.call('/api/projects/:projectId/code/:codeId', {
            method: 'get',
            routeParams: { projectId: pageId, codeId }
          });
        },
        editCodeById: (pageId, codeId, input) => {
          return this.call('/api/projects/:projectId/code/:codeId', {
            method: 'put',
            routeParams: { projectId: pageId, codeId },
            body: input
          });
        },
        editCodeByIdWithRestriction: (pageId, codeId, input) => {
          return this.call('/api/projects/:projectId/code/:codeId/restrict-update', {
            method: 'put',
            routeParams: { projectId: pageId, codeId },
            body: input
          });
        },
        deleteCodes: ({ pageId, ids }) => {
          return this.call('/api/projects/:id/code/bulk-delete', {
            method: 'delete',
            routeParams: { id: pageId },
            body: { ids }
          });
        },
        previewPoints: queryParams => {
          return this.call('/api/hub/preview-points', {
            method: 'get',
            urlParams: queryParams
          });
        },
        duplicateCodeById: (pageId: string, codeId: string) => {
          return this.call('/api/projects/:projectId/code/:codeId/duplicate', {
            method: 'post',
            routeParams: {
              projectId: pageId,
              codeId
            }
          });
        },
        copyCodeToAnotherPage: (pageId: string, codes: Array<string>) => {
          return this.call('/api/projects/copy-code/:projectId', {
            method: 'post',
            routeParams: {
              projectId: pageId
            },
            body: { codes }
          });
        },
        moveCodeToAnotherPage: (pageId: string, codes: Array<string>) => {
          return this.call('/api/projects/move-code/:projectId', {
            method: 'post',
            routeParams: {
              projectId: pageId
            },
            body: { codes }
          });
        },
        previewQRCode: input => {
          return this.call('/api/hub/preview-code', {
            method: 'post',
            body: input
          });
        },
        createWebhookToken: (pageId, input) => {
          return this.call('/api/projects/:projectId/webhook_token', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        upsertWebhookToken: (pageId, input) => {
          return this.call('/api/projects/:projectId/webhook_token/upsert', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        getWebhookTokens: (pageId: string) => {
          return this.call('/api/projects/:projectId/webhook_token', {
            method: 'get',
            routeParams: { projectId: pageId }
          });
        },
        getCategories: (pageId: string) => {
          return this.call('/api/projects/:projectId/public-categories', {
            method: 'get',
            routeParams: { projectId: pageId }
          });
        },
        updateCategory: (pageId, categoryId, input) => {
          return this.call('/api/projects/:projectId/categories/:categoryId', {
            method: 'put',
            routeParams: { projectId: pageId, categoryId },
            body: input
          });
        },
        createCategory: (pageId, input) => {
          return this.call('/api/projects/:projectId/categories', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        destroyCategoryBranch: (pageId, input) => {
          return this.call('/api/projects/:projectId/destroy-category-branch', {
            method: 'delete',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        checkExistCodeInCategoryBranch: (pageId, input) => {
          return this.call('/api/projects/:projectId/check-code-category-branch', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        importCategoryByUrl: (pageId, url) => {
          return this.call('/api/projects/:projectId/categories/import', {
            method: 'post',
            routeParams: { projectId: pageId },
            body: { url }
          });
        },
        exportCategory: pageId => {
          return this.call('/api/projects/:projectId/categories/export', {
            method: 'get',
            routeParams: { projectId: pageId }
          });
        },
        pinningPage: (pageId: string, pinStatus: boolean) => {
          return this.call('/api/projects/:projectId/pinning', {
            method: 'post',
            routeParams: {
              projectId: pageId
            },
            body: { pinStatus }
          });
        },
        getDraftSiteOpts: (pageId: string) => {
          return this.call('/api/projects/:projectId/project_site_opts/draft', {
            method: 'get',
            routeParams: { projectId: pageId }
          });
        },
        updateDraftSiteOpts: (pageId: string, input: any) => {
          return this.call('/api/projects/:projectId/project_site_opts/draft', {
            method: 'put',
            routeParams: { projectId: pageId },
            body: input
          });
        },
        discardDraftSiteOpts: (pageId: string) => {
          return this.call('/api/projects/:projectId/project_site_opts/draft', {
            method: 'delete',
            routeParams: { projectId: pageId }
          });
        },
        getSiteOpts: (pageId: string, urlParams: any) => {
          return this.call('/api/projects/:projectId/project_site_opts', {
            method: 'get',
            urlParams,
            routeParams: { projectId: pageId }
          });
        },
        countSiteOpts: (pageId: string, urlParams: any) => {
          return this.call('/api/projects/:projectId/project_site_opts/count', {
            method: 'get',
            urlParams,
            routeParams: { projectId: pageId }
          });
        },
        publishSiteOpts: (pageId: string, siteOptsId: string, input: any) => {
          return this.call('/api/projects/:projectId/project_site_opts/:siteOptsId/publish', {
            method: 'post',
            routeParams: { projectId: pageId, siteOptsId },
            body: input
          });
        }
      }),
      (this.code = {
        find: (filter: any) => {
          return this.call('/api/codes', {
            method: 'get',
            urlParams: { filter }
          });
        },
        count: (where: any) => {
          return this.call('/api/codes/count', {
            method: 'get',
            urlParams: { where }
          });
        },
        getVersions: (filter: any) => {
          return this.call('/api/code-version', {
            method: 'get',
            urlParams: { filter }
          });
        },
        parseDocxFile: payload => {
          return this.call('/api/codes/parse-docx-code', {
            method: 'post',
            body: payload
          });
        }
      }),
      (this.pushLog = {
        find: (filter: any) => {
          return this.call('/api/push-logs', {
            method: 'get',
            urlParams: { filter }
          });
        },
        count: (where: any) => {
          return this.call('/api/push-logs/count', {
            method: 'get',
            urlParams: { where }
          });
        }
      }),
      (this.setting = {
        updateCompanyById: (companyId, newProfile) => {
          return this.call('/api/company/:companyId', {
            method: 'patch',
            routeParams: { companyId },
            body: newProfile
          });
        },
        changePassword: input => {
          return this.call('/api/user/change-password', {
            method: 'post',
            body: input
          });
        }
      }),
      (this.billing = {
        find: (companyId, filter) => {
          return this.call('/api/company/:companyId/bills', {
            method: 'get',
            urlParams: { filter },
            routeParams: { companyId }
          });
        },
        getBillingById: (companyId, billId) => {
          return this.call('/api/company/:companyId/bills/:billId', {
            method: 'get',
            routeParams: { companyId, billId }
          });
        }
      }),
      (this.sound = {
        cuttingSsmlIntoSentence: ssml => {
          return this.call('/api/sound/cutting-ssml-sentence', {
            method: 'post',
            body: { ssml }
          });
        },
        getSynthesizeSpeechStream: (payload, controller = new AbortController()) => {
          return this.call(
            '/speech',
            {
              method: 'Post',
              body: payload
            },
            'LAMBDA',
            controller
          );
        }
      }),
      (this.oauth = {
        authorize: (accessToken, urlParams) => {
          return this.call('/oauth/authorize', {
            method: 'post',
            urlParams,
            body: { accessToken }
          });
        },
        getClient: id => {
          return this.call('/api/client/:id', {
            method: 'get',
            routeParams: { id }
          });
        }
      }),
      (this.masterData = {
        getMasterData: ({ filter }) => {
          return this.call('/api/master-data', {
            method: 'get',
            urlParams: { filter }
          });
        },
        addMasterData: payload => {
          return this.call('/api/master-data', {
            method: 'post',
            body: payload
          });
        },
        updateMasterDataById: ({ id, payload }) => {
          return this.call('/api/master-data', {
            method: 'patch',
            routeParams: { id },
            body: payload
          });
        }
      }),
      (this.pageGroup = {
        getPageByCategoryId: ({ id, urlParams }) => {
          return this.call('/api/project-group/:id/projects', {
            method: 'get',
            routeParams: { id },
            urlParams
          });
        },
        createPageByCategoryId: ({ id, payload }) => {
          return this.call('/api/project-group/:id/projects', {
            method: 'post',
            routeParams: { id },
            body: payload
          });
        },
        updatePageByCategoryId: ({ categoryId, pageId, payload }) => {
          return this.call('/api/project-group/:id/projects/:fk', {
            method: 'put',
            routeParams: { id: categoryId, fk: pageId },
            body: payload
          });
        },
        deletePageByCategoryId: ({ categoryId, pageId }) => {
          return this.call('/api/project-group/:id/projects/:fk', {
            method: 'delete',
            routeParams: { id: categoryId, fk: pageId }
          });
        }
      }),
      (this.pageGroupMapping = {
        movePageToCategory: ({ pageId, payload }) => {
          return this.call('/api/project-group-mapping/:id', {
            method: 'patch',
            routeParams: { id: pageId },
            body: payload
          });
        }
      }),
      (this.remoteConfig = {
        getRemoteConfig: key => {
          return this.call('/api/remote-config/:key', {
            method: 'get',
            routeParams: { key }
          });
        }
      });
      this.ragtEditor = {
        InsertOrUpdateRagtJson: payload => {
          return this.call('/api/ragt/upsert', {
            method: 'POST',
            body: payload
          });
        },
        getSimplifiedHtml: payload => {
          return this.call('/api/ragt/tiny-html', {
            method: 'POST',
            body: payload
          });
        }
      };
      this.dictionary = {
        getDictionaries: (urlParams) => {
          return this.call('/api/dictionaries', {
            method: 'GET',
            urlParams
          })
        },
        deleteDictionaries: (names) => {
          return this.call('/api/dictionaries', {
            method: 'delete',
            body: { names }
          })
        },
        updateDictionary: (sk, dictionary) => {
          return this.call('/api/dictionaries/:sk', {
            method: 'put',
            body: dictionary,
            routeParams: { sk }
          })
        },
        createDictionary: (dictionary) => {
          return this.call('/api/dictionaries', {
            method: 'post',
            body: dictionary
          })
        },
        updateDictionaryName: (sk, name) => {
          return this.call('/api/dictionaries/update-name/:sk', {
            method: 'put',
            routeParams: { sk },
            body: { name }
          })
        }
      }
  }

  async call(
    url,
    { method, routeParams = {}, urlParams = {}, body = {} },
    server = 'UV',
    controller = new AbortController()
  ): Promise<any> {
    Object.keys(routeParams).forEach(key => {
      url = url.replace(new RegExp(':' + key + '(/|$)', 'g'), routeParams[key] + '$1');
    });
    if (server === 'LAMBDA') {
      return lambdaService.request({
        method,
        params: urlParams,
        url: `${url}`,
        ...(controller && { signal: controller.signal }),
        data: body ? JSON.stringify(body) : undefined
      });
    }
    return service.request({
      method,
      params: urlParams,
      url: `${url}`,
      data: body ? JSON.stringify(body) : undefined
    });
  }
}

const api = new UnivoiceApi();

export default api;
